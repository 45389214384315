// Base

* {
  font-family: "Roboto";
}

body {
  background-color: #F2F2F2;
  padding-right: 0 !important;
  overflow: hidden;
}

.bg-blue {
  background-color: $blue;
  color: $white;
}

.container-menu {
  display: flex;
  flex-direction: row;
}

.rest-negative {
  color: $danger !important;
}

.navbar-toggler {
  border: 0px !important;
  outline: 0px !important;
}

.navbar {
  @media (min-width: 768px) {
    display: none;
  }
}

.nav-link {
  .badge {
    background-color: $blue;
    margin-left: 10px;
    border-radius: 30px;
  }
}

.menu {
  @media (max-width: 767px) {
    display: none;
  }

  min-height: 100vh;
  background-color: #3E4756;
  width: 300px;
  position: relative;

  .container-user {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 15px;
    width: 80%;

    img {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      margin-right: 20px;
      object-fit: cover;
    }

    h2 {
      color: $white;
      font-family: "Roboto Bold";
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .btn-logout {
    color: $white !important;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    box-shadow: none !important;
    border: 0px !important;
    outline: 0px !important;
  }

  .btn-logout-admin {
    color: $white !important;
    position: absolute;
    right: 50px;
    top: 10px;
    font-size: 20px;
    box-shadow: none !important;
    border: 0px !important;
    outline: 0px !important;
  }

  ul {
    padding: 0px !important;
    list-style: none;

    li {
      ul li {
        background-color: #4F5866;
      }
      
      ul li a {
        padding-left: 24px !important;
      }

      &.active {
        background-color: $blue !important;
        border-left: 3px solid $blue !important;
      }

      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white !important;
        text-decoration: none !important;
        padding: 10px !important;

        .badge {
          background-color: $blue;
          margin-left: 10px;
          border-radius: 30px;
        }

        i {
          position: absolute;
          right: 10px;
        }
      }
    }
  }
}
.nav-a > a{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff!important;
  text-decoration: none!important;
  padding: 10px!important;
}

.container-router {
  width: calc(500vw - 10px); // 100  - 300 Por defecto
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  @media (max-width: 767px) {
    padding-top: 70px;
    width: 100vw;
  }
}

.ver-todo {
  color: $blue;
  display: inline-block;
  cursor: pointer;
  font-family: "Roboto Bold";
  margin-top: 0px !important;
}

.tooltip {
  pointer-events: none;
}

.text-color {
  font-weight: bold;
  &__gray {
    color: $gray;
    font-weight: bold;
  }
  &__blue {
    color: $blue;
    font-weight: bold;
  }
  &__black {
    color: $black;
    font-weight: bold;
  }
  &__white {
    color: $white;
    font-weight: bold;
  }
  &__input {
    color: $input;
    font-weight: bold;
  }
  &__active {
    color: $active;
    font-weight: bold;
  }
  &__danger {
    color: $danger;
    font-weight: bold;
  }
  &__green {
    color: $green;
    font-weight: bold;
  }
  &__orange {
    color: $orange;
    font-weight: bold;
  }
  &__yellow {
    color: $yellow;
    font-weight: bold;
  }
}

.text-md {
  font-size: 14px;
}

.text {
  &-provider {
    flex: auto;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

div[role="dialog"].show {
  scrollbar-gutter: stable;
}

#swal2-content {
  white-space: pre-line;
}