// Monthly Earnings

.monthly-earnings {
	.total-amount {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	.total-earnings {
		color: white;
		background-color: $blue;
		border-radius: 10px;
		display: flex;
		align-self: flex-end;
		padding: 0.5rem 1rem;
		margin: 2rem 1rem 0 1rem;

		&.payments {
			background-color: transparent;
			border-radius: 0;
			padding: 1rem;
			margin: 0;

			div:last-child {
				text-align: right;
			}
		}

		div {
			flex: 1;
		}
		div:last-child {
			text-align: center;
		}
	}
}