// Create Edit Orders

.container-create-edit-order {
	.btn-actions-orders {
		margin-left: 5px;
		margin-right: 5px;
	}
	.container-btn-actions {
		display: flex;
		flex-direction: 'row';
	}
}
#modal-edit-order {
	display: flex;
	gap: 4rem;
	font-size: 14px;

	.totals {
		background-color: $gray;
		color: gray;
		border-radius: 12px;
		padding: 5px;
		margin-bottom: 1rem;

		& > div {
			padding: 1px 10px;
			text-transform: uppercase;
			flex: 1;
			font-size: 12px;

			div:last-child {
				flex-direction: row-reverse;
			}
		}
	}
	.container-products {
		margin-top: 1.5rem;
		max-height: 21rem;
		overflow: auto;

		.product {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 10px 0;
			margin-bottom: 10px;

			.product-image {
				display: flex;
				flex: 0.2;
				justify-content: center;

				img {
					border-radius: 5px;
					width: 60px;
					height: 60px;
					object-fit: cover;
				}
			}
			.product-name {
				margin-bottom: 5px;
				font-weight: bold;
			}
			.product-details {
				flex: 1;
			}
			.product-actions {
				display: flex;
				justify-content: center;
				align-items: center;

				button {
					background-color: transparent;
					border: none;
					outline: none;
				}
			}
			.qty-actions {
				padding: 0 1rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.qty {
					display: flex;
					flex-direction: row;

					.qty-input {
						width: 4rem;
						border-radius: 5px;
						background-color: $gray;
						text-align: center;
						outline: none;
						border: none;
						margin: 0 0.5rem;
					}
					.qty-add, .qty-remove {
						cursor: pointer;
						width: 2rem;
						height: 2rem;
						aspect-ratio: 1;
						background-color: $blue;
						border-radius: 5px;
						outline: none;
						border: none;

						i {
							color: $white;
							font-size: 12px;
						}
					}
				}
			}
		}
		.circle {
			cursor: pointer;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
			z-index: 1;
			margin-right: 0.5rem;
			z-index: 2;

			&.selected::after {
				content: '';
				display: flex;
				position: relative;
				z-index: -1;
				width: calc(1.5rem + 6px);
				height: calc(1.5rem + 6px);
				top: -3px;
				left: -3px;
				border-radius: 50%;
				filter: sepia(1);
				background: linear-gradient(to bottom right, #ffffff66, #00000066);
			}
		}
		.no-items {
			text-align: center;
			padding: 5px;
			margin-bottom: 10px;
		}
	}
	.container-products::-webkit-scrollbar {
		width: 5px;
		border-radius: 3px;
		background-color: rgba(0,0,0,0.25);
	}
	.container-products::-webkit-scrollbar-thumb {
		background-color: rgba(0,0,0,0.25);
		border-radius: 3px;
	}
	.tab-container {
		display: flex;
		gap: 2rem;
		justify-content: space-evenly;
		align-self: center;

		.tab {
			cursor: pointer;
			width: 100%;
			padding-top: 10px;
			padding-bottom: 6px;
			border-radius: 10px;
			align-self: center;
			background-color: white;
			text-align: center;
			text-transform: uppercase;
			border-bottom: 2px solid white;
			@include shadow();

			&.selected {
				border-bottom: 2px solid $green;
			}
		}
	}
	.divider {
		height: 1px;
		background-color: $blue;
		margin: 1.5rem 0;
	}
	.flex-end {
		display: flex;
		justify-content: flex-end;
	}
	.flex {
		display: flex;
		flex: 1;

		.form-group {
			width: 100%;
			margin-bottom: 0;

			input {
				height: 40px !important;
			}
		}
	}
	.row {
		display: flex;
		flex-direction: row;
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}
	.row-center {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	.row-between {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	.label {
		font-weight: bold;
	}
	.input-container {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}
	.container-form {
		.input-container {
			margin-top: 0;
		}
		.container-payments {
			border-radius: 20px;
			margin-bottom: 20px;
			padding: 10px;

			.payment-method {
				display: flex;
				flex-direction: row;

				.payment-method-desc {
					flex: 1;
					padding-right: 0.5rem;
				}
				.payment-method-delete {
					margin-left: 1rem;

					button {
						border: none;
						background-color: transparent;
					}
				}
			}
		}
		.container-buttons {
			display: flex;
			gap: 1rem;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;

			button {
				background-color: $green;
				border: none;
				outline: none;
				flex: 1;
				padding: 0.5rem 0;
				border-radius: 30px;

				span {
					color: white;
					font-size: 14px;
					text-transform: uppercase;
				}
			}
			button.danger {
				background-color: transparent;

				span {
					color: red;
				}
			}
		}
	}
	.size-colors-form {
		display: flex;
		justify-content: space-between;
		gap: 1rem;
		padding: 1rem 0.5rem 1rem 0;

		.colors {
			margin-bottom: 15px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.colors, .sizes {
			width: 50%;
		}
	}
	.error-discount {
		color: $danger;
		font-size: 11px;
		margin-bottom: 20px;
	}
}

#modal-accept-order {
	.container-list-date {
		align-self: center;
		margin-bottom: 15px;
		display: grid;
		grid-template-columns: repeat(3, minmax(0,1fr));
	}
	.container-date {
		display: flex;
		flex-direction: row;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.item-date {
		color: $blue;
	}
	.trash-icon {
		cursor: pointer;
		width: 20px;
		height: 20px;
		margin-left: 10px;
	}
	.actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;
	}
}

#modal-view-payments {
	.title {
		font-size: 14px;
		font-weight: bold;
		margin-right: 5px;
	}
	.item {
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid lightgray;
	}
	.no-items {
		padding: 10px;
	}
}
