#commissions {
  .total-amount {
    .label {
      font-family: 'Roboto Medium';
      font-size: 14px;
    }
    .totals {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      span {
        font-size: 13px;
        font-family: 'Roboto Medium';
      }
      span:nth-child(2n) {
        font-family: 'Roboto Bold';
        text-align: right;
        color: $blue;
      }
    }
  }
  table {
    thead tr {
      th {
        white-space: normal;
      }
    }
    tbody {
      td:nth-child(5), td:nth-child(6), td:nth-child(7) {
        text-align: right;
        display: table-cell;
      }
      td:last-child {
        display: table-cell;
      }
    }
  }
}

#commissions-payments {
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > div {
      flex: 1;
    }
    & > div:last-child {
      display: flex;
      gap: 1rem;
    }
  }
  .total-amount {
    .label {
      font-family: 'Roboto Medium';
      font-size: 20px;
    }
    .totals {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      span {
        font-size: 16px;
        font-family: 'Roboto Medium';
      }
      span:nth-child(2n) {
        font-family: 'Roboto Bold';
        text-align: right;
        color: $blue;
      }
    }
  }
  table {
    thead tr {
      th {
        white-space: normal;
      }
    }
    tbody {
      td:nth-child(5), td:nth-child(6), td:nth-child(7) {
        text-align: right;
        display: table-cell;
      }
      td:last-child {
        display: table-cell;
      }
    }
  }
}

#commissions-payments-modal {
  display: flex;
  flex: 1;

  .reset-filters {
    font-family: 'Roboto Bold';
    font-size: 12px;
    margin-bottom: 0px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
  }

  .container-scroll {
    @include scroll();
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }

  @keyframes tab-selected {
    from {translate: -100%;}
    to {translate: 0%;}
  }
  @keyframes tab-unpaid {
    from {translate: 100%;}
    to {translate: 0%;}
  }
  #tab-selected {
    display: flex;
    flex: 1;
    flex-direction: column;
    animation: tab-selected 0.25s forwards;
  }
  #tab-unpaid {
    display: flex;
    flex: 1;
    flex-direction: column;
    animation: tab-unpaid 0.25s forwards;

    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .back-button img {
        filter: brightness(0);
        width: 30px;
        cursor: pointer;
      }
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 100%;
    // margin-left: -1rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;

    .header {
      width: 25%;
      background-color: #112d6b;
      color: #fff;
      font-family: 'Roboto Bold';
      margin-bottom: 0px;
      padding: 5px;
    }

    .item {
      width: 25%;
      padding: 5px;
      margin-bottom: 0px;
      background-color: #e0f2ff;
      display: flex;
      &.white {
        background-color: #fff;
      }
      .actions > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 10px;
        margin-top: -2px;
      }
    }

    .order {
      display: flex;
      flex-direction: row;
      padding: 0.5rem 0.75rem;
      border: 1px solid $gray;
      cursor: pointer;
      transition: all ease 0.1s;

      &.selected {
        border: 1px solid $blue;
      }
      .details {
        flex: 1;

        p {
          font-size: 14px;
          margin-bottom: 0;

          span {
            font-family: 'Roboto Medium';
          }
        }
      }
      .actions {
        img {
          width: 24px;
        }
      }
    }
    .order:hover {
      background-color: #00000018;
    }
    .empty-message {
      text-align: center;
      color: gray;
      width: 100%;
      margin-top: 10px;
    }
  }
  .totals {
    // padding: 0.5rem 0;
    margin-bottom: 1rem;
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px solid darken($gray,20);
    border-width: 1px 0 1px;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    // padding: 10px;

    & > div {
      padding: 5px;
      font-family: 'Roboto Bold';
    }

    // &.active {
      background-color: #e0f2ff;
    // }

    span {
      font-family: 'Roboto Bold';
    }
    span:nth-child(2n) {
      text-align: right;
    }
  }
  footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  select, input, .container-datepicker {
    border: 1px solid lighten(#000,40) !important;
    border-radius: 5px;
    padding: 5px !important;
    font-size: 14px;
    height: 40px;
  }
  .label-datepicker, label {
    font-size: 14px !important;
    margin-bottom: 3px;
    color: #000 !important;
  }
  .btn-send {
    padding: 10px;
    margin: 0 auto;
    max-width: 300px;
  }
  .btn-select-orders {
    height: 40px;
    margin-top: 5px;
  }
  .check {
    border: 1px solid lighten(#000,30);
    border-radius: 5px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    &.active {
      background-color: #63d154;
    }
    img {
      width: 17px;
      height: 17px;
      object-fit: contain;
      @include recolor(#fff);
    }
  }

  .container-title-methods {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    h3 {
      color: #3A7FC2;
      text-align: center;
      margin-bottom: 0px;
      font-size: 18px;
      font-family: 'Roboto Bold';
    }
    .back-button img {
      filter: brightness(0);
      width: 30px;
      cursor: pointer;
    }
  }

  .container-btn-send {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .container-cyan {
    background-color: #e0f2ff;
    border-radius: 10px;
    padding: 10px;
    table {
      border-collapse: collapse;
      padding: 0px;
      p {
        margin-bottom: 0px;
        font-size: 17px;
        font-family: 'Roboto Bold';
        color: #3e4756;
        &.blue {
          color: #14306d;
        }
      }
    }
  }

  .container-fields {
    margin-top: 10px;
    select, input {
      height: 40px !important;
    }
    .container-file {
      border: 1px solid #666666;
      margin-bottom: 15px;
      border-radius: 5px;
      height: 40px;
      overflow: hidden;
      label {
        display: block;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        color: #8e959b;
      }
    }
  }

  .container-totals {
    display: flex;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .container-total {
      border-radius: 7px;
      padding: 10px;
      width: 95%;
      margin: 0 auto;
      p {
        margin: 0px;
        font-family: 'Roboto Bold';
      }
      p.name {
        color: #fff;
      }
      p.total {
        font-size: 18px;
        color: #fff;
      }
      &.blue {
        background-color: #009bf8;
      }
      &.cyan {
        background-color: #e0f2ff;
        p {
          color: #000 !important;
        }
      }
    }
    button {
      border: 1px solid #112d6b !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border-radius: 7px;
      outline: 0px !important;
      margin: 0 auto;
      display: flex;
      padding: 8px;
      p {
        margin: 0px;
        font-family: 'Roboto Bold';
        color: #112d6b;
        text-align: center;
      }
      img {
        width: 20px;
        heigth: 20px;
        @include recolor(darken(#eeeeee,10));
        margin-right: 10px;
        margin-top: 2px;
      }
      &.active {
        img {
          @include recolor(#63d154);
        }
        background-color: #112d6b !important;
        p {
          color: #fff !important;
        }
      }
    }
  }
}

div[role="dialog"] {
  .modal-dialog:has(#commissions-payments-modal) {
    max-width: 700px;

    .modal-content {
      padding: 0 !important;
      margin: 0 !important;
      overflow: hidden;
      min-height: 500px;

      .modal-body {
        display: flex;
        padding-bottom: 0px;
      }
    }
  }
}

.modal-view-payment-individual {
  h3 {
    font-size: 16px;
    font-family: 'Roboto Bold';
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
  }
}