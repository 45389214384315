.account-receivable__payments, #modal-view-payments .order__payment {
  .account-receivable__payment {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }
  .voucher {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;

    .voucher-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .voucher-image {
        width: 32px;
        height: 32px;
        margin-right: 20px;
        object-fit: cover;
      }
    }
    .voucher-icon {
      width: 24px;
      height: 24px;
      background-color: #3a7fc2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      img {
        width: 16px;
        height: 16px;
        filter: brightness(0) invert(1);
      }
    }
  }
}