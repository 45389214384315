#api-token {
  width: 100%;
  .card-title {
    &.h5 {
      font-family: 'Roboto Bold';
    }
  }
  .card-body {
    @include shadow();
    border-radius: 20px;
    background-color: $white;
  }
  .card {
    background-color: transparent !important;
  }
  .label-menu {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid #000;
    padding: 8px;
    img {
      width: 10px;
      height: 10px;
      object-fit: contain;
      margin-right: 10px;
    }
    &.active {
      background-color: $blue;
      color: $white !important;
      border-color: $blue;
      img {
        @include recolor($white);
      }
    }
  }
  .ul-subtitle {
    list-style-type: none;
    padding-left: 15px;
    li {
      margin-top: 5px;
    }
  }
  p {
    font-size: 14px;
  }
  p.label {
    font-family: 'Roboto Medium';
    font-size: 16px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .code-block-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .code-block {
    background-color: $gray-background;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    margin-bottom: 2rem;

    .code-block-header {
      padding: 0.25rem 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: lightgray;
      font-size: 14px;

      .title {
        color: $black;
        font-family: 'Roboto Medium';
      }
      .action {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
      .action:hover {
        background-color: darkgray;
      }
    }
    .code {
      margin-bottom: 0;
      padding: 0.25rem 1rem;
      color: $black;
      font-family: 'Consolas';
    }
    .options {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0.5rem 1rem;
      gap: 1rem;

      div {
        padding: 0.125rem 0.5rem;
        font-size: 14px;
        border-radius: 6px;
        border: 1px solid lightgray;
        font-family: 'Roboto Medium';
        cursor: pointer;

        &.success {
          color: $green;
        }
        &.danger {
          color: $danger;
        }
      }
      div:hover, div.selected {
        border: 1px solid $blue;
      }
    }
  }
  .params {
    margin-bottom: 2rem;

    .params-item {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      .params-key, .params-type, .params-required {
        font-family: 'Consolas';
        font-weight: bold;
      }
      .params-type {
        color: gray;
      }
      .params-required {
        color: $danger;
      }
      .params-description {

      }
    }
  }
  .clickable {
    cursor: pointer;
    &.active {
      color: $blue;
      font-family: 'Roboto Bold';
    }
  }
  // li:hover, .clickable:hover, .link {
  //   text-decoration: underline;
  //   color: $blue,
  // }
  .right-content {
    background-color: transparent;

    .table-container {
      box-shadow: none;
      border: 1px solid $gray;
      margin-bottom: 2rem;
    }
    .json-response {
      font-size: 14px;
    }
  }

  .example {
    white-space: pre-line;
    font-family: monospace;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .card-text {
    color: black;
  }
  
  .sidebar {
    flex-basis: 0;
  }
  
  .nav-link {
    color: #3A7FC2;
    cursor: pointer;
  }
  
  .nav-link.active {
    background-color: #343a40;
    font-weight: bold;
  }
  
  .json-response {
    white-space: pre-wrap;
  }
}