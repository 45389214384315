#recover {
  --recover-width: 25rem;
  --recover-tab-width: 8rem;

	height: 100vh;
	display: flex;
  background-color: white;

  .left {
    flex: 3;
    overflow: auto;
  }
  .logo {
    display: flex;
    padding: 1.5rem 2rem 1rem;

    img {
      margin: auto;
      width: 14rem;
    }
  }
  .go-back {
    position: absolute;
    background-color: transparent;
    margin: 1rem 2rem 1rem;
    outline: none;
    border: none;

    img {
      width: 2rem;
      height: 2rem;
      filter: invert(1);
    }
  }
  header {
    h4 {
      text-align: center;
      font-family: 'Roboto Bold';
      color: $blue2;
    }
    p {
      margin-bottom: 0;
    }
  }
  form {
    max-width: var(--recover-width);
    width: 100%;
    margin: 2rem auto 0;
    padding: 1rem 3rem;
    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
    -webkit-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
    -moz-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);

    h5 {
      color: #309bf9;
      font-family: 'Roboto Medium';
      margin-bottom: 1rem;
      text-align: center;
    }
    .inputs{
      flex: 1;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 1rem;

      .form-group {
        border: 1px solid lightgray;
        border-radius: 6px;
        overflow: hidden;

        input {
          font-size: 14px;
        }
      }
    }
    button {
      background-color: #309bf9;
      width: 180px !important;
    }
  }
  footer {
    max-width: var(--recover-width);
    width: 100%;
    margin: 1rem auto;
    padding: 0 15px;
  }
  .divider {
    max-width: 100%;
    height: 1px;
    background-color: black;
    margin: 0.5rem auto 2rem;
  }
}