

:root {
  --landscape-width: 22; // % OR vw for responsive window resizing! 22
  --portrait-width: 100%;
  --header-height: 35px;
  --secs: 0.6s;
  --bg-left: springgreen;
  --bg-right: cyan;
  --bg-main: lemonchiffon;
}
#layout {
  // display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout

  // Style in common for sidebars and main area:
  // .header {
  //   position: relative;
  //   height: var(--header-height);
  //   text-align: center; // Horizontal center
  //   .title { position: absolute; left: 0; right: 0; }
  // }
  header {
    display: flex;
    height: 70px;
    flex-direction: row;

    .logo {
      width: 300px;
      height: 70px;
      background-color: white;
      display: inline-flex;
      align-items: center;
      position: relative;

      img {
        width: 90%;
      }
      .toggle-menu{
        width: 30px;
        height: 30px;
        margin-right: unset;
        position: absolute;
        right: 1rem;
      }
    }
    .content-header {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 20px 0 20px;
      height: 70px;
      // margin-bottom: 30px;
      color: white;
      font-family: 'Inter Regular';
      background-color: $blue;

      .group {
        display: flex;
        gap: 1rem;
        flex-direction: row;
        align-items: center;
      }
      .back-button {
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          position: relative;
          cursor: pointer;
        }
      }
      .content-breadcumbs {
        .breadcumbs {
          font-size: 11pt;
        }
        .title {
          font-size: 13pt;
          font-family: 'Inter SemiBold';
        }
      }
      .logout {
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
          flex-direction: row;
          align-items: center;
          border-radius: 25px;
          border: 2px solid white;
          padding: 5px 10px;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            margin-bottom: 3px;
          }
        }
      }
    }
  }
  .content {
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    padding: 1rem;
  }
  .toggle-menu {
    background-color: transparent;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    // border-radius: 6px;
    margin-right: 1rem;

    img {
      width: 20px;
      filter: brightness(0);
    }
  }
}



// Style in common for both sidebars:
#left, #right {
  transition: width var(--secs);
  // width: 0;

  @media (orientation: landscape) {
    &.open { width: calc(1% * var(--landscape-width)); } // POR DEFECTO LLEVA 1%
  } // <-- Landscape ONLY sidebars
  .icon { position: fixed; z-index: 10;
    width: var(--header-height); height: var(--header-height);
    line-height: var(--header-height); font-size: var(--header-height);
    text-align: center; user-select: none; cursor: pointer;
    color: $text;
    //background-color: #222d32;
    background-color: #3E4756;
    border-radius: 50%;
  }
  #sidebar { transition: transform var(--secs); // <-- Portrait AND landscape!
    @media (orientation: portrait) { width: var(--portrait-width); }
    @media (orientation: landscape) { width: calc(1vw * var(--landscape-width)); }
    .header { width: calc(100% - var(--header-height)); }
  }
}

// Left sidebar specific style:
#left {
  z-index: 5;

  #sidebar {
    height: 100%;
    background-color: white;
    width: 300px;
    transition: all 0.3s ease-out;
    // &.closed { transform: translateX(-100%); } // <-- Left portrait AND landscape!
    &.closed {
      width: 50px;

      .container-menu .menu .items > li {
        a span,
        a.dropdown-toggle::before {
          display: none;
        }
      }
    }
    .header { left: var(--header-height); }

    .logo {
      width: 300px;
      height: 70px;
      background-color: white;
    }
    .user {
      background-color: $white;
      padding: 0 1rem;

      div {
        display: flex;
        align-items: flex-start;

        .info p {
          margin-bottom: 0px;
          font-family: 'Inter SemiBold';
        }
      }
      .divider {
        margin-top: 0.5rem;
        border-bottom: 2px solid white;
      }
    }
    .container-menu .menu {
      overflow-y: hidden;
      background-color: $white;
      min-height: calc(100vh - 70px) !important;

      .items {
        margin-top: 10px;

        &>li {
          margin: 0 5px;
          border-radius: 13px;
          background-color: white !important;

          ul {
            overflow: hidden;
          }

          a {
            padding: 0 !important;
            background-color: transparent;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              font-family: 'Inter Regular';
              color: $black !important;
              margin-left: 10px;
            }
            .icon-button {
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              outline: none;
              border-radius: 12px;
              background-color: transparent;

              img {
                width: 24px;
                height: 24px;
              }
            }
          }
          a.dropdown-toggle::before {
            content: "";
            position: absolute;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid gray;
            transition: all 0.3s ease-out;
          }
          & > a {
            margin-top: 0.5rem;
          }
          .navbar-collapse {
            overflow: hidden;

            li {
              background-color: transparent;

              a {
                height: 35px;
                padding-left: 50px !important;
                font-size: 10pt;
                border-radius: 0;
              }
              a:hover, &.active, &.active a {
                font-family: 'Inter Medium';
                background-color: $blue !important;
                color: white !important;
                border: none !important;
              }
            }
          }
        }
      }
      .sub-menu-active {
        padding-right: 0;
      }
      a[aria-expanded="true"] {
        background-color: transparent !important;
        font-family: 'Inter SemiBold';
      }
      a[aria-expanded="true"] + ul {
        background-color: #e0e0e0 !important;
      }
      .items li.li-active a .icon-button {
        background-color: $blue !important;

        img {
          filter: brightness(0) contrast(1) grayscale(1) sepia(1) saturate(1) invert(1);
        }
      }
      a i {
        transition: all ease-out 200ms;
        color: black;
      }
      a[aria-expanded="true"].dropdown-toggle::before {
        transform: rotate(180deg);
      }
      a::after {
        display: none;
      }
      .dropdown-toggle {
        font-family: 'Inter Regular';
        color: black !important;
      }
      .nav-link {
        position: relative;
        font-family: 'Inter Regular';
        color: black !important;
        text-align: center;
      }
    }
  }
}

@media (max-width: 640px) {
  #layout {
    header .logo {
      width: 50px !important;
      justify-content: center;

      & > img {
        display: none;
      }
      .toggle-menu {
        position: relative;
        right: 0;
      }
    }
    #left {
      #sidebar {
        position: absolute;

        &.closed {
          translate: -50px;
        }
        .menu {
          display: block !important;
        }
      }
    }
  }
}

.container-app {
  display: flex;
  flex-direction: row;

  .container-fluid {
    padding: 1rem;
    flex: 1;
    height: calc(100vh - 70px);
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
}