// Home

#home {
	.form-group {
		margin-bottom: 10px !important;
		&.m-0{
			margin-bottom: 0px ;
		}
	}

	.minh {
		height: 80vh;
	}

	span.clickable {
		text-decoration: underline;
    text-decoration-color: $blue;
    text-underline-offset: 5px;
    text-decoration-style: dashed;
		cursor: pointer;
	}

	span.clickable-red {
		text-decoration: underline;
    text-decoration-color: #F51616;
    text-underline-offset: 5px;
    text-decoration-style: dashed;
		cursor: pointer;
	}
}

.minh {
	height: 70vh;
}

.container-view-user {
	img {
		margin-bottom: 20px;
		margin-top: 10px;
	}

	li {
		text-align: left;
	}
}