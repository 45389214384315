// Login
#login {
	display: flex;
	flex-direction: column;
	height: 100vh;

	.copy {
		display: flex;
		background-color: #d3d3d3;
		padding: 0.5rem 0;

		p {
			text-align: center;
			margin: 0 auto;
			color: rgba(0, 0, 0, 0.6);
		}
	}
}

.bg {
	object-fit: cover;
	object-position: left;
	height: 100vh;
	position: absolute;
	right: 0;
}
#login {
	& > main {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		background-color: white;
	}

	&:before {
		content: "";
		width: 160px;
		height: 350px;
		background-color: #309bf9;
		position: absolute;
		z-index: 2;
		bottom: -50px;
		left: -120px;
		transform: rotate(-20deg);
	}
	.left {
		position: relative;
		width: 50vw;
	}
	.right {
		position: relative;
		width: 50vw;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.slider {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.content {
				width: 80%;
				display: flex;
				flex-direction: row;

				img {
					width: 100%;
					object-fit: contain;
					display: none;
					opacity: 0;
					transition: all ease-in 1s;

					&.active {
						display: block;
						opacity: 1;
					}
				}
			}
			nav {
				position: absolute;
				bottom: 15vh;
				left: 0;
				right: 0;
				display: flex;
				flex-direction: row;
				gap: 1rem;
				justify-content: center;
				align-items: center;

				.dot {
					cursor: pointer;
					width: 10px;
					height: 10px;
					border-radius: 5px;
					background-color: rgba(255, 255, 255, 0.5);
					transition: all ease-in 0.1s;

					&.active {
						background-color: white;
					}
				}
				.dot:hover {
					scale: 1.5;
				}
			}
		}
	}
	.container-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		background-color: $blue;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		border-radius: 200px;
		position: relative;
		top: -70px;
		margin: 0 auto;
		margin-bottom: -50px;
	}
	.logo {
		display: flex;
		justify-content: center;
		margin-bottom: 1.5rem;

		img {
			width: 320px;
		}
	}
	.left:has(.onboard) .logo img {
		width: 400px;
	}
	.show-pass {
		display: flex;
		justify-content: space-between;

		&>div {
			display: flex;
			gap: 0.5rem;
			align-items: center;
			cursor: pointer;
			font-size: 13px;
			color: $blue;
		}
	}
	form, .terms {
		max-width: 360px;
		width: 100%;
		margin: 0 auto 1rem auto;
		padding: 1rem 1.5rem;
		border-radius: 10px;

		label {
			margin: 0;
			color: gray;
		}
		.link {
			font-size: 13px;
			font-family: 'Inter Medium';
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.form-group {
			border: 1px solid #888888;
			border-radius: 10px;
			overflow: hidden;

			input {
				// background-color: #f2f2f2 !important;
				height: 36px !important;
				font-size: 14px;
			}
			input::placeholder {
				color: $black;
			}
		}
		button {
			background-color: #50d177;
		}
	}
	.terms {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 6px;
	}
	.highlighted {
		color: #309bf9;
		font-family: 'Inter Medium';
		font-size: 14px;
		cursor: pointer;
		text-decoration: underline;
	}
	.onboard {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		max-width: 360px;
		margin: 0 auto 1rem auto;

		button {
			border-radius: 20px;
		}
		button.secondary {
			background-color: transparent;
			border: 1px solid $black !important;

			span {
				color: $black;
			}
		}
		a:hover {
			text-decoration: none;
		}
	}
}

@media (min-width: 883px) {
	#login {
		.bg {
			width: 60vw !important;
		}
	}
}

@media (max-width: 882px) {
	#login::before {
		z-index: 1;
	}
	#login > main {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		gap: 5rem;

		.left {
			width: auto !important;
			padding: 1rem;
			border-radius: 1rem;
			inset: 0;
			z-index: 1;
			width: inherit;
			height: inherit;
			background-color: #ffffff50;
			border: 2px solid #ffffff33;
			backdrop-filter: blur(20px);
			transition: 250ms;
			transform-style: preserve-3d;
			transform-origin: top left;
			overflow: hidden;
		}
		.right {
			width: 80vw !important;
			height: unset !important;

			.slider nav {
				bottom: -10px !important;

				.dot {
					width: 7px !important;
					height: 7px !important;
				}
			}
		}
		.logo > img {
			width: 50vw !important;
		}
	}
	.highlighted {
		color: white !important;
	}
}

@media (max-width: 640px) {
	#login > main {
		gap: 3rem;

		.right {
			width: 90vw !important;
		}
		.logo > img {
			width: 60vw !important;
		}
	}
}