// Pagination

.pagination {
	.page-link, .page-item {
		text-decoration: none !important;
		outline: 0px !important;
		box-shadow: none !important;
	}
	.page-item.active {
		.page-link {
			background-color: $blue !important;
			border-color: $blue !important;
		}
	}
}