// Support

#support {
	.triangle-left {
      width: 0;
      height: 0;
      border-top: 15px solid $gray;
      border-right: 15px solid transparent;
      position: absolute;
      bottom: -15px;
      left: 0;
	}
	.no-chat {
		font-family: 'Roboto Condensed';
		font-size: 20px;
		text-align: center;
		margin-top: 10px;
	}
	.triangle-right {
      width: 0;
      height: 0;
      border-top: 15px solid $blue;
      border-left: 15px solid transparent;
      position: absolute;
      bottom: -15px;
      right: 0;
	}
	.container-chat {
		height: 95vh;
		background-color: $white;
		width: 100%;
		@include shadow();
	}
	.list-chats {
		height: 95vh;
		overflow-y: scroll;
		border-right: 1px solid $gray;
		@include scroll();
	}
	.no-padding-left {
		padding-left: 0px !important;
	}
	.no-padding-right {
		padding-right: 0px !important;
	}
	.item-chat {
		padding: 10px;
		border-bottom: 1px solid $gray;
		position: relative;
		cursor: pointer;
		&.active {
			background-color: $input;
		}
		.badge {
			position: absolute;
			right: 5px;
			top: 5px;
			background-color: $blue;
			color: $white;
			width: 20px;
			height: 20px;
			border-radius: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		i.fa {
			position: absolute;
			right: 5px;
			bottom: 5px;
			color: $green;
		}
		h2 {
			color: $blue;
			font-family: 'Roboto Bold';
			font-size: 12px;
			margin: 0px !important;
		}
		p:not(.date) {
			font-size: 13px;
			margin: 0px !important;
			margin-top: 5px;
			margin-bottom: 5px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 80%;
		}
		p.date {
			font-size: 12px;
			margin: 0 !important;
			color: #616161;
		}
	}
	.top-messages {
		background-color: $blue;
		display: flex;
		color: $white;
		align-items: center;
		height: 40px;
		h2 {
			font-family: 'Roboto Condensed';
			color: $white;
			font-size: 16px;
			margin-left: 10px;
			margin-top: 5px;
			span {
				font-family: 'Roboto Condensed Bold';
			}
		}
		button {
			position: absolute;
			right: 10px;
			background-color: $white !important;
			height: 30px;
			width: 30px;
			i.fa {
				color: $blue !important;
			}
		}
	}
	.container-messages {
		height: 95vh;
		position: relative;
		.container-input {
			position: absolute;
			bottom: 0px;
			display: flex;
			flex-direction: row;
			background-color: $blue;
			min-width: 100%;
			justify-content: center;
			align-items: center;
			padding: 0px !important;
			.form-group {
				width: 90%;
			}
			input {
				position: relative;
				top: 8px;
				border-radius: 30px !important;
			}
			button {
				width: 40px;
				height: 40px;
				min-width: auto !important;
				border-radius: 80px;
				margin-left: 10px;
				background-color: $white !important;
				i.fa {
					color: $blue !important;
					font-size: 30px;
					margin-top: -3px;
					margin-left: 2px;
				}
			}
		}
		.container-scroll {
			@include scroll();
			display: flex;
			flex-direction: column;
			padding: 10px;
			overflow-y: scroll;
			height: calc(95vh - 58px - 40px);
			background-color: $white;
		}
		.message {
			padding: 10px;
			width: 50%;
			margin-top: 10px;
			margin-bottom: 10px;
			border-radius: 3px;
			position: relative;
			h2 {
				font-size: 12px;
			}
			p {
				color: #616161;
				font-size: 11px;
				margin-bottom: 0px !important;
			}
			&.right {
				background-color: $blue;
				align-self: flex-end;
				text-align: right;
				border-bottom-right-radius: 0px !important;
				margin-right: 0px;
				h2 {
					color: $white;
				}
				p {
					color: $white;
				}
			}
			&.left {
				border-bottom-left-radius: 0px !important;
				background-color: $gray;
				align-self: flex-start;
			}
		}
	}
}