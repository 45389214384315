// Table

.table {
	margin-bottom: 0 !important;
}

.white-spaced-table {
	th {
		white-space: normal;
	}
}

.table-component {
	background-color: $white;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;

	thead {
		border-bottom: 1px solid $gray;

		tr {
			white-space: nowrap;
		}

		th {
			border: 0px !important;
			width: 28%!important;

			&.white-space {
				white-space: normal;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid $gray;
		}
		
		td {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 200px;

			button {
				margin-right: 5px;
			}
		}
	}
}

.red-row {
	color: red;
}

.no-table-data {
	font-family: 'Roboto Bold Italic';
	font-size: 18px;
	background-color: $white;
	border-radius: 2px;
	text-align: center;
	padding: 10px;
	color: $blue;
}

.table-container {
	//@include shadow();
	background-color: #fff;
	border-radius: 10px;
	// margin: 70px auto 0;
	padding-top: 10px;
	padding-bottom: 20px;
	padding-left: 15px;
	padding-right: 15px;
	// width: 85%;
	box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
	-webkit-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
	-moz-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);

	.title-component {
		background-color: transparent;
		color: $blue;

		.title-component-right button {
			background-color: transparent !important;

			img {
				width: 30px;
				margin-top: -5px;
			}
		}
	}

	.table-responsive {
		table {
			thead {
				color: lightgray;

				th {
					font-family: 'Inter Medium';
					font-weight: unset;
					font-size: 11pt;
				}
				th:last-child {
					text-align: right;
				}
			}
			tbody {
				color: #062f59;

				td:last-child {
					display: flex;
					text-align: right;
					justify-content: flex-end;
				}
			}
		}
	}
}

.ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
}