#register {
  --register-width: 36rem;
  --register-tab-width: 8rem;

	height: 100vh;
	display: flex;
  background-color: white;

  .left {
    flex: 3;
    overflow: auto;
  }
  .right {
    flex:2 ;
    height: 100%;

    img {
      background-size: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .logo {
      width: 20rem;
      object-fit: contain;
      object-position: bottom;
      position: absolute;
    }
  }
  .logo {
    display: flex;
    padding: 1.5rem 2rem 1rem;

    img {
      width: 12rem;
    }
  }
  .go-back {
    background-color: transparent;
    margin: 0 2rem 1rem;
    outline: none;
    border: none;

    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .stepper {
    width: calc(var(--register-width) - 4rem);
    position: relative;
    margin: 0 auto 2rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    .track {
      position: absolute;
      display: flex;
      justify-content: center;
      top: calc(1.5rem - 1px);
      left: 0;
      right: 0;

      .thumb {
        width: calc(100% - var(--register-tab-width));
        height: 2px;
        background-color: darkgray;
      }
    }
    .tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: var(--register-tab-width);
      gap: 0.5rem;

      .circle-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle {
          cursor: pointer;
          position: relative;
          width: 3rem;
          height: 3rem;
          border-radius: 1.5rem;
          border: 2px solid darkgray;
          outline: 0.5rem solid white;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
      &.active .circle {
        border-color: $blue;
      }
      label {
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  form {
    max-width: var(--register-width);
    width: 100%;
    margin: 1rem auto 0;
    padding: 1rem 0 0;

    .form-control.mui {
      margin-bottom: 0.5rem;
    }
  }
  footer {
    max-width: var(--register-width);
    width: 100%;
    margin: 1rem auto;
    padding: 0 15px;
  }
  .register-with {
    margin: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }
  .register-with__btn {
    // cursor: pointer;
    background-color: white;
    @include shadow();
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1rem;
    flex: 1;
    gap: 1rem;

    img {
      width: 2rem;
      height: 2rem;
    }
    span {
      font-size: 14px;
    }
  }
  .divider {
    max-width: 100%;
    height: 1px;
    background-color: black;
    margin: 0.5rem auto 2rem;
  }
}