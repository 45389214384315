// Title

.title-component {
	// background-color: $blue;
	width: 100%;
	padding: 10px;
	font-size: 18px;
	font-family: 'Roboto Bold';
	color: $blue;
	margin: 0px !important;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	position: relative;
	min-height: 41px;
	.title-component-right {
		position: absolute;
		right: 10px;
		display: inline-block;
		top: 8px;
	}
	span {
		font-family: 'Roboto Bold';
	}
}