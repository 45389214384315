// Reports

.new-reports {
	.monthly-earnings.flex {
		display: flex;
		flex-wrap: wrap;
		.total-amount {
			margin-right: 10px;
		}
	}
	.total-amount {
		.container-total {
			background-color: #ffffff;
			@include shadow2();
			padding: 10px;
			border-radius: 8px;
			margin-bottom: 10px;
			&.blue {
				background-color: #009bf8;
				& > span {
					color: #fff;
				}
				& > div {
					color: #fff !important;
				}
			}
			&.cyan {
				background-color: #e0f2ff;
			}
			& > span {
				font-size: 14px;
				font-family: 'Roboto Bold';
				color: #009bf8;
			}
			& > div {
				color: #000 !important;
				font-size: 16px;
			}
		}
	}
	.form-group {
		label {
			font-family: 'Roboto Bold';
		}
		input, select {
			border: 1px solid lighten(#000,50) !important;
			border-radius: 5px !important;
		}
	}
	.btn-filter {
		border-radius: 5px !important;
		&.blue {
			background-color: #009bf8 !important;
			span {
				color: #fff !important;
			}
		}
	}
	.container-buttons {
		display: flex;
		button {
			margin-right: 10px;
		}
	}
	.table-component {
		th > div {
			font-family: 'Roboto Bold';
			color: #000;
		}
	}
	.title-component {
		span {
			display: none;
		}
	}
	.table-container  {
		margin-bottom: 15px;
	}
	.container-datepicker {
		border: 1px solid lighten(#000,50) !important;
		border-radius: 5px !important;
	}
	.no-table-data {
		padding: 0px !important;
		margin: 0px !important;
		margin-top: 10px !important;
	}
	&.report-commisions {
		.container-total {
			padding: 8px;
			& > span {
				font-size: 13px !important;
			}
		}
	}
	.custom-actions-select .input-wrapper {
		border: 1px solid lighten(#000,50) !important;
		border-radius: 5px !important;
	}
}

.fiscal-container {
	background-color: #fff;
	border-radius: 10px;
	@include shadow();
	max-width: 300px;
	margin: 0 auto;
	padding: 20px;
	h3 {
		color: #009bf8;
		font-size: 18px;
	}
	p {
		font-size: 14px;
	}
	button {
		margin-bottom: 10px;
		width: 100%;
		background-color: #e0f2ff;
		border-radius: 5px !important;
		border: 1px solid #009bf8;
		padding: 10px;
		display: flex;
		div {
			font-family: 'Roboto Bold';
			margin-left: 5px;
			color: #000;
			text-align: center;
			flex: 1;
			margin-top: 2px;
		}
		img {
			width: 30px;
			heigth: 30px;
			object-fit: contain;
		}
		&:hover {
			background-color: #009bf8;
			span {
				color: #fff;
			}
			img {
				@include recolor($white);
			}
		}
	}
}