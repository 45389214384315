// Modal Serial

.modal-serial {
	.product_name {
		font-size: 14px;
		border: 1px solid #c0c0c0;
		border-radius: 3px;
		padding: 5px;
		display: inline-block;
		font-weight: bold;
		padding-left: 15px;
		padding-right: 15px;
	}
	.row-flex {
		display: flex;
		flex-direction: row;
		&.flex-right {
			justify-content: flex-end;
		}
		&.row-flex-buttons {
			max-width: 300px;
			button {
				margin-right: 5px;
				margin-left: 5px;
			}
		}
	}
	.col-flex {
		flex: .5;
	}
	&.white {
		background-color: #fff;
		padding: 20px;
		border-radius: 3px;
		margin-bottom: 25px;
	}
}