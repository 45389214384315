#conversion {
  width: 20rem;
  background-color: $white;
  margin: 2rem auto;
  border-radius: 10px;
  overflow: hidden;

  .conversion-header {
    background-color: $blue;
    color: $white;
    padding: 0.5rem 1rem;
  }

  .conversion-form {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      flex: 1;
    }
    .label{
      flex: 1;
    }
    .form-group {
      margin-bottom: 0;

      .form-control {
        border: 1px solid gray !important;
        background-color: $white;
        display: flex;
        flex: 1;
      }
    }
  }
  .actions {
    padding: 1rem;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}

#printer {
  background-color: transparent;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  border-radius: none;
  .printer-form {
    width: 20rem;
  }
  .label {
    font-family: 'Roboto' !important;
  }
  .form-group .form-control {
    padding: 8px !important;
    border-radius: 5px !important;
    height: 40px !important;
    border: 1px solid gray !important;
    background-color: $white;
    display: flex;
    flex: 1;
  }
  .conversion-form {
    // padding: 0.75rem 1rem;

    div:first-child {
      flex: 2;
    }
    div:last-child {
      flex: 3;
    }
    select {
      padding: 0 0.5rem;
      font-size: 14px;
    }
  }
  .style-fullview {
    position: fixed;
    inset: 0;
    z-index: 100;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .close-fullview {
      position: fixed;
      top: 1rem;
      right: 1rem;

      img {
        width: 2rem;
        height: 2rem;
        filter: invert(1);
        cursor: pointer;
      }
    }
    .img-wrapper img {
      max-height: 95vh;
    }
  }
  .style-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
    width: 12rem;

    p {
      text-align: center;
      margin-bottom: 0.5rem;
    }
    img {
      width: 9rem;
      max-height: 50rem;
      object-fit: contain;
      cursor: pointer;
    }
  }
}

#currency {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 20rem;
  margin: auto;
  gap: 1rem;

  .bg-blue {
    background-color: $blue !important;
    color: $white;
  }
  .currency-item {
    outline: none;
    border: none;
		border-radius: 14px;
		background-color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
		padding: 15px;
		@include shadow();

    &.selected {
      background-color: $green;

      .currency-symbol, .currency-name {
        color: white;
      }
    }

    .currency-symbol {
      font-family: 'Roboto Bold';
      font-size: 24px;
      text-align: 'center';
      color: $blue2;
    }
    .currency-name {
      font-family: 'Roboto Medium';
      font-size: 16px;
      text-align: 'center';
      color: $blue2;
    }
  }
}