// Create edit user

.container-create-edit-user {
	.label-photo {
		font-family: 'Roboto Bold';
		margin-bottom: 5px;
	}
	.container-photos {
		border: 1px dashed $blue;
		width: 100%;
		border-radius: 5px;
		margin-bottom: 10px;
		label[for="photo"], .label-uploaded {
			display: flex;
			padding: 10px;
			margin-bottom: 0px;
			align-items: center;
			.upload-icon {
				width: 80px;
				height: 80px;
				object-fit: contain;
				margin-right: 20px;
			}
			p {
				margin-bottom: 5px;
			}
		}
	}
	.container-uploaded-photos {
		display: flex;
		flex-wrap: wrap;
	}
	.container-photo-individual {
		border: 1px dashed $blue;
		width: 125px;
		height: 125px;
		padding: 10px;
		border-radius: 5px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		margin-right: 10px;
		&.active {
			border: 1px solid $blue;
		}
		position: relative;
		.container-close {
			position: absolute;
			top: 10px;
			right: 10px;
			background-color: $white;
			width: 23px;
			height: 23px;
			border-radius: 11.5px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			z-index: 2;
			img {
				width: 14px;
				height: 14px;
				object-fit: contain;
			}
		}
		label[for="photo"], .label-uploaded {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-bottom: 0px;
			.upload-icon {
				margin-bottom: 10px;
				margin-top: 10px;
				width: 50px;
				height: 50px;
				object-fit: contain;
			}
			p {
				font-size: 18px;
				margin-bottom: 0px;
				text-align: center;
				color: $blue;
				text-align: center;
				line-height: 25px;
				text-transform: uppercase;
				font-family: 'Roboto Bold';
			}
			.uploaded-photo {
				width: 150px;
				height: 150px;
				object-fit: cover;
			}
		}
	}
    .container-cedula {
        display: flex;
    }
    .btn-actions-orders {
		margin-left: 5px;
		margin-right: 5px;
	}
	.container-btn-actions {
		display: flex;
		flex-direction: 'row';
		justify-content: center;
	}
	table .table-title {
		color: $blue;
		font-family: 'Inter SemiBold';
	}
	.permission-item {
		list-style: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 40px;
		cursor: pointer;
		border-bottom: 1px solid lightgray;

		.checkbox {
			width: 24px;
			height: 24px;
			border: 1px solid gray;
			background-color: white;
			border-radius: 50%;
		}
		&.selected {
			.checkbox {
				background-color: $green;
				border-color: green;
			}
		}
	}
	.zones, .sellers {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		padding: 0;

		li {
			list-style: none;
			cursor: pointer;
			border: 1px solid $blue;
			padding: 0.25rem 0.5rem;
			border-radius: 5px;
			transition: 200ms;
			font-size: 14px;
		}
		li:hover {
			background-color: #DD4B4B36;
			border: 1px solid $danger;
		}
	}
	.autocomplete-dropdown-container {
		padding: 0.5rem 0;
		background-color: $white;
		position: absolute;
		z-index: 100;
		width: calc(100% - 2rem);
		border-top: 1px solid $gray;
		border-bottom: 1px solid $gray;

		.suggestion-item,
		.suggestion-item--active {
			background-color: $white;
			cursor: pointer;
		}
		.suggestion-item--active {
			background-color: $gray;
		}
		.suggestion-item:hover,
		.suggestion-item--active:hover {
			background-color: $gray;
		}
	}
	.autocomplete-dropdown-container:empty {
		display: none;
	}
	.client-gps {

		#map-create-client {
			width: 100%;
			aspect-ratio: 16/9;
			background-color: $gray;
			margin: 0.5rem 0 1.5rem;
			border-radius: 4px;
		}
	}
	@media screen and (max-width: 1200px) {
		.form-group label.material {
			font-size: 14px !important;
		}
	}
	textarea.form-control {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
}