.modal {

	&-order {
		.modal-dialog {
			max-width: 85% !important;

			@media (max-width: 600px) {
				max-width: 100% !important;
			}
		}
	}

	&-purchase,
	&-product,
	&-delivery {
		.modal-dialog {
			max-width: 80% !important;

			@media (max-width: 600px) {
				max-width: 100% !important;
			}
		}
	}
}

.modal-green {

	.modal-header,
	.modal-body {
		background-color: forestgreen !important;
	}

	.modal-dialog {
		height: 100vh;
		max-width: 80% !important;

		@media (max-width: 600px) {
			max-width: 100% !important;
		}
	}
}