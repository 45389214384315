// Cash Count

.modal-cash-count {
  .modal-dialog {
    width: 50%;
  }
}

.manage-cash-count {
	.subtitle {
    font-size: 11pt;
    color: gray;
    margin-bottom: 10px;
  }

  .last-cash-count {
    font-size: 12pt;
    color: $blue;
  }

  hr {
    border-color: $purple !important;
  }

  .title {
    color: $black;
    font-size: 12pt;
    font-weight: bold;
  }

  .cash-in, .cash-out, .cash-balance {
    color: $blue;
    font-size: 18pt;
    font-weight: bold;
  }

  .cash-out {
    color: $text;

    &.zero {
      color: gray;
    }
  }

  .withdraw label {
    color: $blue;
  }

  .withdraw-before {
    input {
      margin-right: 0.5rem;
    }
    label {
      font-size: 12pt !important;
    }
  }

  .input-suffix {
    position: absolute;
    margin-top: 5px;
    margin-left: 110px;
    font-size: 10pt;
    color: $black;
  }

  .link {
    font-size: 12pt;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .resume-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
    border-bottom: 1px solid lightgray;

    &.no-border {
      border: none;
    }

    .border {
      border-radius: 2px;
      border: 1px solid gray;
      min-width: 60px;
      padding: 0 5px;
      text-align: right;
    }
  }
}