// Modal

.modal {
	outline: 0px !important;
	.modal-dialog {
		outline: 0px !important;
	}
	.modal-title {
		font-family: 'Roboto Bold';
		color: $blue;
		font-size: 18px;
	}
	.modal-content:not(.custom-modal-content) {
		background-color: transparent !important;
		border-color: transparent !important;
		/* New */
		padding-left: 12px;
		padding-right: 12px!important;
	}
	.modal-header {
		border-bottom: 0px !important;
		background-color: white;
		border-top-right-radius: 10px !important;
		border-top-left-radius: 10px !important;
		button.close {
			outline: 0px !important;
			color: gray !important;
		}
	}
	.modal-body {
		background-color: $white;
		border-bottom-right-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
		
	}
	label {
		font-size: 14px !important;

		&.material {
			font-size: medium !important;
			color: #3E4756;
		}
	}

	.modal-serials-inputs{
		width: 450px;
		max-height: 250px;
		overflow: auto;
	}
}

.custom-modal-bd {
	opacity: 0 !important;
}

.custom-modal-content {
	border-color: rgba(0,0,0,.2) !important;

	.close-btn {
		cursor: pointer;
		position: absolute;
		top: -10px;
		right: -12px;
	}
	.modal-header, .modal-body {
		border-radius: 10px !important;
		background-color: #F2F2F2;
	}
}