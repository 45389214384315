// Input

.input-group {
	input.form-control {
		border-radius: 3px;
		background-color: $input !important;
		outline: 0px !important;
		box-shadow: none !important;
		border: 0px !important;
		font-size: 13px;
		height: 30px!important;
		margin-top: 10px;
		margin-bottom: 10px;

		&.input-white {
			background-color: $white !important;
			height: 30px!important;
		}

		&.input-gray {
			background-color: $gray !important;
		}
		&-sm{
			border-radius: 3px;
			background-color: $input !important;
			outline: 0px !important;
			box-shadow: none !important;
			border: 0px !important;
			font-size: 13px;
		}
	}

	.input-group-text {
		border-radius: 3px;
		background-color: $input !important;
		outline: 0px !important;
		box-shadow: none !important;
		border: 0px !important;
		font-size: 13px;
		height: 30px!important;
		margin-top: 10px;
		margin-bottom: 10px;

		button.btn {
			min-width: 0px !important;
			background-color: transparent !important;

			i.fa {
				color: $danger !important;
			}
		}

		&.input-white {
			background-color: $white !important;
		}

		&.input-gray {
			background-color: $gray !important;
		}
	}
}