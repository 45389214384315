#dashboard {
  margin: 1rem;

  .widgets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    gap: 1rem;
    margin-bottom: 2rem;

    &.row {
      margin: 0px;
    }
    .widget {
      background-color: $white;
      min-height: 10rem;
      display: flex;
      flex-direction: column;
      position: relative;
      @include shadow();

      .widget-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.5rem 0;

        .widget-title {
          color: $blue;
          font-size: 16px;
          font-weight: bold;
          margin: 0;
        }
        .widget-actions {
          button {
            background-color: transparent;
            border: none;
          }
        }
      }
      .widget-content {
        position: relative;
        overflow: auto;
        @include scroll();

        &.chart-container {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
        }
        &.full-height, .full-height {
          display: flex;
          flex: 1;
          flex-direction: column;
        }
        &.content-center, .content-center {
          justify-content: center;
          align-items: center;
        }
        .calendar {
          margin-left: 0.5rem;
          font-size: 12px;
          color: #888888;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.25rem;

          img {
            filter: brightness(0) contrast(0);
          }
          .date-range {
            text-transform: capitalize;
          }
        }
        .xl-text {
          font-size: 26px;
          line-height: 26px;
          color: $text;
        }
        .big-text {
          font-size: 36px;
          line-height: 36px;
          color: $text;
        }
        .font-bold {
          font-weight: bold;
        }
        .legend {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 0.25rem;
          font-size: 13px;

          .legend-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.blue {
              background-color: #309bf9;
            }
            &.green {
              background-color: #50d177;
            }
          }
        }
        .chart-custom-label {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          font-size: 12px;
          color: #888888;
          text-align: center;
          margin-left: 0.5rem;

          & > div {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            padding: 0 5px;
          }
        }
        .chart-custom-grid-legend {
          margin: 1rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          font-size: 12px;

          .legend-item {
            display: flex;
            align-items: center;
            gap: 5px;
            padding-right: 5px;
            word-break: break-all;

            .dot {
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background-color: white;
              aspect-ratio: 1/1;
            }
            .label {
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
        .horizontal-bar {
          height: 1.5rem;
          width: 100%;
          max-width: 280px;
          border-bottom: 3px solid #AAAAAA;
          margin-top: 0.25rem;

          &.blue {
            background-color: $blue;
          }
          &.turquoise {
            background-color: #0fe2db;
          }
        }
      }
      &#wg--monthly {
        width: 100%;
      }
      &#wg--to-pay {
        width: 100%;
      }
      &#wg--top-products {
        width: 100%;
      }
      &#wg--annual-request {
        width: 100%;
      }
      &#wg--top-clients {
        width: 100%;
      }
      &#wg--sales-seller {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .widget {
      &#wg--monthly {
        width: 50% !important;
      }
      &#wg--to-pay {
        width: calc(50% - 1rem) !important;
      }
      &#wg--top-products {
        width: calc(50%) !important;
      }
      &#wg--annual-request {
        width: calc(50% - 1rem) !important;
      }
      &#wg--top-clients {
        width: calc(50%) !important;
      }
      &#wg--sales-seller {
        width: calc(50% - 1rem) !important;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .widget {
      &#wg--monthly {
        width: 50% !important;
      }
      &#wg--to-pay {
        width: calc(50% - 1rem) !important;
      }
      &#wg--top-products {
        width: calc(50%) !important;
      }
      &#wg--annual-request {
        width: calc(50% - 1rem) !important;
      }
      &#wg--top-clients {
        width: calc(50%) !important;
      }
      &#wg--sales-seller {
        width: calc(50% - 1rem) !important;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .widget {
      &#wg--monthly {
        width: 50% !important;
      }
      &#wg--to-pay {
        width: calc(25% - 1rem) !important;
      }
      &#wg--top-products {
        width: calc(25% - 1rem) !important;
      }
      &#wg--annual-request {
        width: calc(20% - 1rem) !important;
      }
      &#wg--top-clients {
        width: calc(40% - 1rem) !important;
      }
      &#wg--sales-seller {
        width: 40% !important;
      }
    }
  }

  @media only screen and (min-width: 1400px) {
    .widget {
      &#wg--monthly {
        width: 50% !important;
      }
      &#wg--to-pay {
        width: calc(25% - 1rem) !important;
      }
      &#wg--top-products {
        width: calc(25% - 1rem) !important;
      }
      &#wg--annual-request {
        width: 20% !important;
      }
      &#wg--top-clients {
        width: calc(30% - 1rem) !important;
      }
      &#wg--sales-seller {
        width: calc(50% - 1rem) !important;
      }
    }
  }
}