/*
** Products
*/

.w800 {
  width: 800px;
}

.w100 {
  width: 100%;
}

.word-break {
  word-break: break-word;
}

#modal-upload-excel {
  .modal-body {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    .banner {
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      img {
        width: 90px;
        padding: 1rem 0;
      }
    }
    .content {
      padding: 3rem 5rem;

      button {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          margin-right: 0.5rem;
        }
      }
    }
    .actions {
      display: flex;
      gap: 1rem;

      button, label {
        height: 40px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
      }
      button {
        color: $blue;
        border: 2px solid #3a7fc2 !important;
      }
      label {
        background-color: #230B63;
        color: white;

        img {
          width: 24px;
          margin-right: 0.5rem;
          filter: invert(1) contrast(1);
        }
      }
    }
  }
}