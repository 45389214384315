// Input
.mixing-blend .form-group label {
	mix-blend-mode: difference;
}

.container-datepicker.filter {
	justify-content: space-between;
}

.form-group:has(.mui) {
	display: flex;
	flex-direction: column-reverse;
}

input.form-control, .container-datepicker {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 13px;
	height: 30px!important;

	&.filter {
		background-color: $white !important;
		border-radius: 10px;
		height: 40px !important;
		font-size: medium;
	}
	&.material {
		background-color: transparent !important;
		border: none;
		height: 40px !important;
		border-bottom: 2px solid $input !important;
		font-size: medium;
		padding: 0;
	}
	&.exchange {
		font-size: 20pt;
    font-weight: bold;
		margin-top: 5px;
	}
	&.input-white {
		background-color: $white !important;
		height: 30px !important;
	}
	&.input-gray {
		background-color: $gray !important;
	}
	&.mui {
		height: 36px !important;
		border: 2px solid #f1f1f1 !important;
		border-bottom-color: $blue !important;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		transition: all 200ms ease;
	}
	&.mui:focus, &.mui[value]:not([value=""]) {
		background-color: white !important;
		border-color: $blue !important;
		border-radius: 6px;
	}
	&.mui:invalid {
		background-color: white !important;
		border-color: $danger !important;
		border-radius: 6px;
	}
	&.mui ~ label {
		color: gray;
		background-color: #f1f1f1;
		font-size: 14px;
		font-weight: normal;
		position: absolute;
		pointer-events: none;
		left: 14px;
		top: 8px;
		transition: all 200ms ease;
	}
	&.mui:focus ~ label, &.mui[value]:not([value=""]) ~ label {
		background-color: white;
		font-size: 12px;
		top: -8px;
		left: 9px;
		padding: 0 5px;
	}
	&-sm{
		border-radius: 3px;
		background-color: $input !important;
		outline: 0px !important;
		box-shadow: none !important;
		border: 0px !important;
		font-size: 13px;
	}
}

/**
* Autocomplete
*/

#suggestion {
	width: 100%;
	height: auto;
	display: block;
	overflow: auto;
	max-height: 100px;
	background: cornflowerblue;
	z-index: 10;
	box-shadow: 10px 5px 5px #605f5f;
	transition: all .6s;
	margin-bottom: 25px;
	&:empty{
		margin-bottom: 0;
	}
	 div {
		color: white;
		padding: 5px;
		cursor: pointer;
	}
}

.m4 {
	margin: 4px;
}

.w50px {
	width: 50px;
}

.height-225-scroll {
	overflow: auto;
	height: 225px;
}