// Modal Scan

.modal-scan {
	
    //width: 700px;
    //height: 237px;

	.quantity {
		font-size: 20px;
		font-family: 'Roboto Light';
		margin: 0px;
		span {
			color: $blue;
		}		
	}

	.quantity-order {
		font-size: 12px;
		font-family: 'Roboto Light';
		margin: 0px;
		span {
			color: $blue;
		}		
	}

	.note-serialize {
		font-size: 12px;
		margin-bottom: 20px;
	}

	.row-flex {
		display: flex;
		flex-direction: row;
		&.flex-right {
			justify-content: flex-end;
		}
		&.row-flex-buttons {
			max-width: 300px;
			button {
				margin-right: 5px;
				margin-left: 5px;
			}
		}
	}
	.col-flex {
		flex: .5;
	}

	.container-serial-manual {
		display: flex;

		button {
			margin-left: 20px;
		}
	}
}