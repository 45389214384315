#my-profile-account {
  // background-color: $white;
  // width: min-content;
  // height: min-content;
  margin: 0 auto;
  // border-radius: 10px;
  // overflow: hidden;
  // width: 50rem;
  // @include shadow();

  nav {
    display: flex;
    place-content: center center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 20px;
    flex-wrap: wrap;

    button {
      height: 3.25rem;
      // width: 10rem;
      background-color: #F1FAFF;
      border-radius: 10px;
      border: 0px solid $text;
      outline: none;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      @include shadow();
      @media (max-width: 600px) {
        width: 100%;
      }

      &.active {
        background-color: $blue;
        // border-color: $blue;

        span {
          color: $white;
        }
        img {
          filter: brightness(0) invert(1);
        }
      }
      img {
        width: 2rem;
        height: 2rem;
        object-fit: contain;
      }
      span {
        font-family: 'Roboto Medium';
        display: inline-block;
        margin-right: 15px;
        margin-left: 15px; 
      }
    }
  }
  .account-tab {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1100px;
    margin: auto;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }

    .title-section {
      font-family: 'Roboto Medium';
      font-size: 14px;
      color: $blue2;
      margin: 1rem 0 0;
    }

    section {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
  }
  .section-label {
    font-size: 22px;
    color: #152b3f;
    font-family: 'Roboto Bold';
    // margin: 1rem 2rem;
    margin-bottom: 15px;
  }
  .business-tab {
    .control-group {
      display: block;
    }
    .profile {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      @media (max-width: 800px) {
        flex-wrap: wrap;
      }

      .avatar-wrapper {
        padding-right: 20px;
        .avatar {
          width: 25vw;
          min-height: 25vw;
          @include shadow();
          border: 0px solid $blue;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          background-color: #fff;
          margin: 0;
          cursor: pointer;
          position: relative;
          @media (max-width: 700px) {
            width: 100%;
            min-height: 35vw;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &.btn-edit {
              position: absolute;
              right: 10px;
              bottom: 10px;
              width: 40px;
              height: 40px;
              object-fit: contain;
            }
            &.placeholder {
              width: 3.5rem;
              height: 3.5rem;
            }
          }
          input {
            display: none;
          }
        }
        .filename {
          color: $blue;
          font-family: 'Roboto Bold';
          font-size: 18px;
        }
        .filename-subtitle {
          font-size: 14px;
        }
      }
    }
    .container-white {
      background-color: #fff;
      border-radius: 10px;
      @include shadow();
      padding: 20px;
      margin-bottom: 10px;
    }
    .catalogue {
      // background-color: #F1FAFF;
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;

      & > section {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .section-big {
        background-color: #002DB2;
        width: 100%;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: white;
          font-family: 'Roboto Bold';
          font-size: 18px;
          margin: 0;
        }
      }
      .show-prices {
        display: flex;
        align-items: center;
        gap: 3rem;

        p {
          margin: 0;
          // font-family: 'Roboto Bold';
        }
        .checkbox-items {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          gap: 3rem;

          .checkbox-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            input[type="checkbox"] {
              appearance: none;
              width: 16px;
              height: 16px;
              border-radius: 8px;
              background-color: #F1FAFF;
              border: 1px solid #888888;
              cursor: pointer;
            }
            input[type="checkbox"]:checked {
              background-color: $green;
              border-color: $green;
            }
            label {
              cursor: pointer;
              margin: 0 0 0 0.5rem;
              font-size: 14px;
              &[for="show-prices-yes"], &[for="show-prices-no"] {
                font-size: 18px !important;
              }
            }
          }
        }
      }
      .social {
        width: 24rem;
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0.5rem;
        margin-bottom: 2rem;

        input {
          font-size: 14px;
          padding: 0 0.5rem;
          border-radius: 4px;
          border: 1px solid #888888;
        }
      }
      .label {
        font-family: 'Roboto Bold';
        margin-bottom: 10px;
        color: #152b3f;
      }
      .url-control {
        background-color: $white;
        border: 1px solid #888888;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 2.75rem;
        font-size: 14px;

        .prefix {
          display: flex;
          place-items: center;
          color: #6d6d75;
          margin: 0.5rem;
        }
        input {
          text-align: center;
          border: none;
          outline: none;
          color: $blue2;
          font-size: 18px;
          font-family: 'Roboto Medium';
          width: 100%;
        }
        button {
          border: none;
          border-radius: 4px;
          margin: 0.25rem;
          background-color: $green;
          color: $white;

          &::before {
            content: 'COPIAR';
          }
          &:focus::before {
            animation: onClickCopy 2s linear;
          }

          @keyframes onClickCopy {
            from {
              content: 'COPIADO';
            }

            to {
              content: 'COPIADO';
            }
          }
        }
      }
      .link {
        margin-top: 1rem;
        color: $blue2;
        font-family: 'Roboto Bold';
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
  .container-public-tab {
    width: 90%;
    display: flex;
    cursor: pointer;
    padding: 10px;
    &.active {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      .tab-arrow {
        @include recolor($white);
      }
      .image {
        @include recolor($white);
      }
    }
    .tab-arrow {
      margin-right: 10px;
      width: 15px;
      height: 15px;
      margin-top: 5px;
      object-position: contain;
    }
    p {
      margin: 0px !important;
    }
    .image {
      width: 20px;
      heigth: 20px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
  .public-tab-padding {
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 90%;
    margin-bottom: 5px;
  }
  .social-container {
    background-color: #ecf6f7;
    border-top: 5px solid #d1d1d1;
    border-bottom: 5px solid #d1d1d1;
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    label {
      display: block;
      color: $blue;
      font-family: 'Roboto Bold';
    }
    .row {
      width: 100%;
    }
    .section-label {
      text-align: left;
      width: 100%;
    }
    input {
      width: 100%;
      border-radius: 5px !important;
      box-shadow: none !important;
      outline: 0px !important;
      border: 1px solid #d1d1d1 !important;
      padding: 8px;
      font-size: 14px;
    }
  }
  .container-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(59,160,250);
    background: linear-gradient(321deg, rgba(59,160,250,1) 0%, rgba(236,246,247,1) 100%);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    @include shadow();
    padding: 15px;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
    & > div {
      min-width: 250px;
      margin: 0 auto 0 0;
    }
    .plan-asset {
      width: 120px;
      height: 120px;
      margin-right: 25px;
      object-fit: contain;
    }
    h4 {
      font-family: 'Roboto Medium';
      font-size: 18px;
      margin-bottom: 0;
      color: $blue2;
    }
    p {
      font-size: 14px;
      margin-bottom: 10px;

      img {
        width: 12px;
        height: 12px;
        object-fit: contain;
        margin-right: 6px;
        filter: brightness(0);
      }
      span {
        font-family: 'Roboto Bold';
      }
      span:last-child {
        font-family: 'Roboto';
      }
    }
    .payments-btn {
      text-transform: uppercase;
      font-family: 'Roboto Bold';
      padding: 0;
      color: $blue2;
      text-decoration: underline;
      background-color: transparent;
      outline: none;
      border: none;
    }
    .plan-actions {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
    }
    .change-plan-btn {
      flex: 1;
      background-color: $blue2;
      color: $white;
      border: none;
      outline: none;
      border-radius: 5px;
      padding: 0.25rem 0.5rem;
      font-size: 14px;
    }
    .pay-btn {
      flex: 1;
      background-color: $green;
      color: $white;
      border: none;
      outline: none;
      border-radius: 5px;
      padding: 0.25rem 0.5rem;
      font-size: 14px;
    }
  }
  .control-group {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // flex: 2;
    // gap: 0.5rem 1rem;
    margin-top: 30px;

    .control {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      &.w-start {
        grid-column: 1 / 2;
      }
      &.w-end {
        grid-column: 2 / -1;
      }
      &.w-full {
        grid-column: 1 / -1;
      }
      label {
        margin-bottom: 0;
        font-size: 14px;
        font-family: 'Roboto Medium';
      }
      input, textarea {
        border: 1px solid #888888;
        border-radius: 6px;
        outline: none;
        padding: 0 0.5rem;
      }
      input {
        height: 2rem;
      }
      textarea {
        height: 4rem;
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    // margin-top: 0.5rem;

    p {
      margin-top: 0 !important;
      font-family: 'Roboto Bold';
    }
    .share-buttons {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
  .appearance {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // padding: 0 1rem;
    // width: 100%;
    width: 100%;

    label {
      margin: 0;
    }
    p {
      font-family: 'Roboto Bold';
      margin-top: 0 !important;

      // &:not(:first-child) {
      //   margin-top: 2rem !important;
      // }
    }
    .banner-upload {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      position: relative;

      .banner-preview {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        height: 4rem;
        width: 19rem;

        img {
          height: 4rem;
          width: 19rem;
          object-fit: cover;
          object-position: top;
        }
        img.placeholder {
          width: 2rem;
          height: 2rem;
        }
      }
      .banner-upload__delete {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .banner-input {
        height: 4rem;
        width: 19rem;
        background-color: white;
      }
      input[type="file"] {
        display: none;
      }
    }
    .control {
      label {
        margin-right: 1rem;
      }
    }
    .preview {
      flex: 1;
      position: relative;
      height: 500px;

      iframe {
        position: absolute;
        border: none;
        background-color: #FFFFFF;
        transform: scale(0.5, 0.5);
        // width: 1024px;
        // height: 1024px;
        top: -50px;
        left: -50px;
        transform-origin: 0 0;
        width: 200%;
        height: 1000px;
        @media (max-width: 991px) {
          top: 0px;
          left: 0px;
          margin-top: 30px;
          height: 900px;
        }
      }
      p {
        text-align: center;
      }
    }
  }
  .save-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    height: 2.25rem;
    outline: none;
    border: none;
    background-color: $blue2;
    border-radius: 6px;
    width: 10rem;
    justify-content: center;
    margin-top: 20px;

    &.primary {
      background-color: $blue;
    }
    img {
      width: 1rem;
      height: 1rem;
      object-fit: contain;
      filter: invert(1);
    }
    span {
      color: $white;
      font-size: 14px;
      font-family: 'Roboto Medium';
    }
  }
  .container-tab-settings {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    .tab-settings {
      margin-left: 10px;
      margin-right: 10px;
      background-color: #e9f6ff;
      border-radius: 10px;
      padding: 10px;
      width: 150px;
      @include shadow();
      cursor: pointer;
      p {
        margin: 0px;
        text-align: center;
        font-size: 14px;
      }
      &.active {
        background-color: #152b42;
        p {
          color: $white;
          font-family: 'Roboto Bold';
        }
      }
    }
  }
  .iva-container {
    display: flex;
    flex-wrap: wrap;
    p {
      margin: 0px;
      margin-right: 15px;
      margin-top: 0px !important;
    }
  }
  .container-methods {
    background-color: #fff;
    border-radius: 5px;
    @include shadow();
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 15px;
    .item-check-method {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      margin-top: 5px;
      @include noSelect();
      p {
        margin: 0px;
      }
      .check {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: .5px solid darken(#fff,20);
        background-color: darken(#fff,5);
        &.active {
          background-color: $green;
          border-color: $green;
        }
      }
    }
  }

  .container-tabs-custom {
    border-bottom: 8px solid #dedede;
    width: 100%;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    .container-buttons {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      flex-wrap: wrap;
      button {
        color: #102339 !important;
        font-family: 'Roboto Bold';
        font-size: 18px;
        outline: 0px !important;
        box-shadow: none !important;
        padding-right: 10px;
        padding-left: 10px;
        paddingt-top: 10px;
        border: 0px !important;
        min-width: 200px;
        margin-right: 20px;
        margin-left: 20px;
        margin-top: 15px;
        &:after {
          content: '';
          display: block;
          height: 8px;
          margin-top: 10px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          width: 110%;
          margin-left: -5%;
        }
        &.active:after {
          background-color: #2f9cf9;
        }
      }
    }
  }
  .custom-title {
    font-family: 'Roboto Bold';
    color: #102339;
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .separator {
    border-bottom: 8px solid #dedede;
    width: 100%;
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
  .custom-container {
    width: 100%;
    .item-check {
      display: flex;
      margin-bottom: 10px;
      margin-top: 15px;
      &.margin-left {
        padding-left: 30px;
      }
      &.margin-left-70 {
        padding-left: 50px;
      }
      p {
        margin: 0px;
        margin-left: 10px;
        font-size: 15px;
        flex: 1;
      }
      .check {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid #dedede;
        cursor: pointer;
        background-color: $white;
      }
    }
    .btn-range {
      display: flex;
      color: #2f9cf9;
      outline: 0px !important;
      box-shadow: none !important;
      border: 0px !important;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        @include recolor($white);
      }
      p {
        margin: 0px;
        font-size: 16px;
        flex: 1;
      }
      .container-add {
        margin-right: 5px;
        background-color: #2f9cf9;
        width: 25px;
        height: 25px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    p {
      .form-group {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        top: -20px;
        margin-right: 10px;
        margin-left: 10px;
        label {
          font-size: 12px;
          text-align: center;
          font-family: 'Roboto Bold';
          margin-bottom: 0px;
        }
        input {
          width: 80px;
          border-radius: 5px !important;
          border: 1px solid #222 !important;
          outline: 0px !important;
          box-shadow: none !important;
          outline: 0px !important;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
  .custom-white {
    background-color: $white;
    @include shadow();
    border-radius: 10px;
    padding: 10px;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    h4 {
      color: #2f9cf9;
      font-family: 'Roboto Bold';
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  .row-check {
    margin-bottom: 15px;
  }
  .row-select {
    max-width: 600px;
    margin: 0 auto;
    select {
      border-radius: 5px;
    }
    .form-group {
      i.fa {
        font-size: 10px !important;
      }
    }
  }
}

#profile-business-payment {
  width: 30rem;
  background-color: white;
  border-radius: 1.5rem;
  @include shadow();
  padding: 1.5rem 1rem 2rem;
  margin: auto;

  p {
    margin-bottom: 0;
  }
  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 12px;
    color: #000000AA;

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-icon {
        width: 2.5rem;
        height: 2.5rem;
        aspect-ratio: 1;
        border: 1px solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: brightness(0) opacity(0.5);

        &.active {
          border: 1px solid #2f9cf9;
          filter: none;
        }
        img {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
      }
      p {
        margin-top: 0.5rem;
      }
    }
    .divider {
      width: 5rem;

      .line {
        height: 2px;
        background-color: lightgray;
        margin: 0 0.5rem;
        flex: 1;

        &.active {
          background-color: #2f9cf9;
        }
      }
      p {
        margin-top: 0.5rem;
        visibility: hidden;
      }
    }
  }
  .method-step {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 20rem;

    .summary {
      width: 100%;
      display: flex;
      background-color: #ECF6F7;
      border-radius: 0.5rem;
      margin: 2rem 0;
      padding: 5px;
      gap: 0.5rem;

      &-icon {
        width: 2.5rem;
        height: 2.5rem;
        aspect-ratio: 1;
        background-color: $blue2;
        border-radius: calc(0.5rem - 3px);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
        }
      }
      &-details p {
        font-size: 16px;
        font-family: 'Roboto Bold';
        line-height: 20px;
        color: $blue2;
      }
    }
    .method-option {
      width: 100%;
      display: flex;
      border-radius: 0.5rem;
      background-color: white;
      border: 1px solid lightgray;
      padding: 0.25rem;
      gap: 0.5rem;
      cursor: pointer;
      margin-bottom: 1rem;

      &-icon {
        width: 2.5rem;
        height: 2.5rem;
        aspect-ratio: 1;
        background-color: #ECF6F7;
        border-radius: calc(0.5rem - 3px);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 1.75rem;
          height: 1.75rem;
          object-fit: contain;
        }
      }
      &-details {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }
  }
  .form-step {
    padding: 1rem;

    .back-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
      cursor: pointer;

      img {
        width: 1.75rem;
        height: 1.75rem;
        object-fit: contain;
        filter: invert(1);
      }
    }
    .hightlighted {
      font-family: 'Roboto Bold';
      color: $blue2;
    }
    .summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ECF6F7;
      padding: 0.5rem;
      margin-bottom: 1rem;
      border-radius: 0.5rem;

      p {
        font-size: 14px;
        font-family: 'Roboto Bold';
        color: $text;
      }
    }
    .method-info {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .save-btn {
      display: block;
      margin: 2rem auto 0;
      width: 50%;
      background-color: $green;
      border: none;
      outline: none;
      padding: 0.5rem;
      font-size: 14px;
      color: white;
      border-radius: 0.5rem;
    }
  }

  form {
    width: 100%;

    label {
      font-size: 14px;
      font-family: 'Roboto Medium';
      color: $text;
      margin-bottom: 2px;
    }
    select, input {
      background-color: white !important;
      border: 1px solid lightgray !important;
      height: 40px !important;
      border-radius: 0.5rem;
    }
    .phone-input {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
    }
  }

  .loading-text {
    height: 16px;
    border-radius: 0.25rem;
    margin-bottom: 4px;
  }
  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    color: transparent;
  }

  @keyframes skeleton-loading {
      0% {
          background-color: #ECF6F7;
      }
      100% {
          background-color: #E2E2E2;
      }
  }
}