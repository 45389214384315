

.tbody .name1{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin: 0;
  
}
