// Select Search

.container-select-search {
	position: relative;
	.form-control {
		padding: 0px;
		font-size: 14px;
	}
	.css-yk16xz-control, .css-1pahdxg-control {
		width: 100%;
		background-color: $white !important;
		border: 1px solid #e8e8e8 !important;
		box-shadow: none !important;
		outline: 0px !important;
		height: 42px;
		border-radius: 10px;
	}
	.css-2b097c-container {
		outline: 0px !important;
		border: 0px !important;		
	}
	.css-1uccc91-singleValue {
		font-size: 14px;
	}
	.image-search-input {
		width: 20px;
		position: absolute;
		top: 11px;
		right: 10px;
	}
	&.search-right {
		.css-1hb7zxy-IndicatorsContainer, 
		.css-tlfecz-indicatorContainer, 
		.css-1gtu0rj-indicatorContainer,
		.css-1okebmr-indicatorSeparator {
			display: none;
		}
	}
}