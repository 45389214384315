#returns {
  main {
    display: flex;
    flex-direction: row;
    align-items: start;

    .table-container {
      flex: 1;

      .table-responsive table tbody {
        td {
          vertical-align: middle;
          input {
            width: 4rem;
            text-align: center;
          }
        }
        td:last-child {
          display: table-cell;
        }
      }
      button.table-row-action {
        border: none;
        background-color: transparent;

        img {
          width: 18px;
        }
      }
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;

    .filters {
      display: flex;
      flex-direction: row;

      label {
        font-size: 15px;
        font-family: 'Roboto Medium';
      }
    }
    .actions {
      display: flex;
      gap: 1rem;

      button span {
        color: white;
      }
    }
  }
  .totals {
    background-color: white;
    width: 14rem;
    border-radius: 12px;
    margin-left: 1.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
    -webkit-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
    -moz-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);

    .title {
      font-size: 18px;
      font-family: 'Roboto Bold';
      color: $blue;
    }
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.modal-returns {
  .question {
    text-align: center;
    font-family: 'Roboto Medium';
    margin-top: 2rem;
    padding: 0 3rem;
  }
  .resp-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .resp-row-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      input {
        appearance: none;
        position: relative;
        cursor: pointer;
      }
      input[type="checkbox"]::after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: $gray;
        border: 1px solid gray;
        position: absolute;
        top: -9px;
      }
      input[type="checkbox"]:checked::after {
        background-color: $green;
        border: 1px solid $green;
      }
      label {
        margin-bottom: 0;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }
  footer {
    display: flex;
    justify-content: center;
    gap: 1rem;

    button:first-child {
      background-color: lightgray !important;
      span {
        color: $black;
      }
    }
    button:last-child {
      background-color: $blue !important;
      span {
        color: white;
      }
    }
  }
}

#returns-credit-note {
  margin: auto;
  max-width: 900px;

  .title {
    color: $blue;
  }
  main {
    background-color: white;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
    -webkit-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);
    -moz-box-shadow: -2px 7px 10px -6px rgba(0,0,0,.44);

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    .header div:last-child {
      p {
        text-align: right;
      }
    }
    .text-red {
      color: red;
    }
    table {
      margin-top: 1rem;
      width: 100%;

      td {
        text-align: center;
        font-size: 14px;
      }
      thead {
        background-color: $blue;
        td {
          color: white;
        }
      }
      tbody {
        td {
          padding: 0.5rem 0;
        }
      }
    }
    .totals {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      div {
        display: grid;
        grid-template-columns: 6rem 6rem;

        span {
          font-family: 'Roboto Bold';
        }
        span:nth-child(2n) {
          text-align: right;
        }
      }
    }
  }
  footer {
    display: flex;
    justify-content: center;
    gap: 1rem;

    button span {
      color: white;
    }
    button:first-child {
      background-color: #152b42 !important;
    }
    button:last-child {
      background-color: $blue !important;
    }
  }
}