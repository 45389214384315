// Create Edit Request

.container-create-edit-request {
	.btn-actions-orders {
		margin-left: 5px;
		margin-right: 5px;
	}
	.container-btn-actions {
		display: flex;
		flex-direction: 'row';
	}

	table.table-products {
		thead {
			tr {
				text-align: center;
				font-size: 11pt;
				color: #062f59 !important;
			}
			tr:first-child td {
				font-family: 'Inter SemiBold';
				font-size: 12pt;
				color: $blue;
			}
		}
		tbody {
			tr.total-row td, tr.total-row th {
				border: none !important;
			}
		}
	}
}

.report-sale-tabs {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	height: 100%;

	.report-sale-tab {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		background-color: black;
		color: white;
		font-weight: bold;
		padding: 0.5rem;
		cursor: pointer;

		&.active {
			background-color: white;
			color: #0044A7;
		}
		&:not(.active) img {
			filter: invert(1);
		}
		img {
			width: 28px;
			height: 28px;
			object-fit: contain;
		}
	}
}

#report-sale-create {
	padding-top: 1rem;

	.loader-wrapper {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;

		.loader {
			width: 20px;
			height: 20px;
			border-radius: 10px;
			border: 3px solid lightblue;
			border-right-color: $blue;
			animation: l2 1s infinite linear;
		}
		@keyframes l2 {to{transform: rotate(1turn)}}
	}

	.bg-dark, .btn-dark {
		background-color: #152b42 !important;
		color: $white;
	}
	button {
		padding: 0.5rem 1rem;
		border-radius: 8px;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;

		&.btn-white {
			color: $black !important;
		}
	}
  .container-title {
		margin-bottom: 1rem;
		flex-direction: column;

		span {
			font-size: 28px;
			font-weight: bold;
		}
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      position: relative;
      top: -5px;
      cursor: pointer;
    }
  }
  .container-buttons {
    margin-top: 20px;
  }
	.filters-row {
		display: flex;
		flex: 1;
		justify-content: space-between;
	}
	.filters {
		display: flex;
		flex: 1;
		flex-direction: column;
		margin: 0;

		& > div {
			padding: 0;
		}
		.container-select-search .form-control {
			background-color: transparent !important;
		}
		.custom-actions-select-wrapper {
			.input-wrapper {
				border: 1px solid hsl(0,0%,80%) !important;
				border-radius: 4px;

				.form-control {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					border: none !important;

					span {
						padding: 0 0.5rem;
					}
				}
				input.form-control {
					padding: 0 0.5rem;
					background: white !important;
				}
				.fa-chevron-down {
					font-size: 12px;
				}
			}
		}
	}
	.client-info {
		display: flex;
		flex-direction: column;
		margin-top: 1rem;

		.info-items {
			display: flex;
			flex-direction: row;
			margin-bottom: 1rem;

			& > div {
				width: 20%;
				display: flex;
				flex-direction: column;
			}
			.label {
				font-weight: bold;
				font-size: 14px;
			}
		}
	}
	.client-address {
		display: flex;
		flex-direction: row;

		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			margin-right: 0.25rem;
		}

		span.clickable {
			text-decoration: underline;
			text-decoration-color: $blue;
			text-underline-offset: 5px;
			text-decoration-style: dashed;
			cursor: pointer;
		}
	}
	.payment-type {
		max-width: 30rem;
		display: flex;
		justify-content: space-between;
		gap: 1rem;

		button {
			box-shadow: none !important;
		}
		.btn-white {
			border: 1px solid gray !important;
		}
		.btn-block {
			margin: 0;
		}
	}
	main {
		display: flex;
		gap: 1rem;

		section.payment {
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: space-between;
			gap: 1rem;
		}
	}
	section.inputs {
		width: 100%;
		display: flex;
		gap: 1rem;
		justify-content: space-between;

		& > div {
			padding: 0;
		}
		.form-group {
			margin: 0;

			input {
				background-color: white !important;
				border-radius: 6px !important;
				border: 1px solid gray !important;
				padding: 0 0.5rem;
			}
		}
	}
	.tables {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 2rem 1rem;

		.table-wrapper {
			margin: 0;
		}
		.payment-methods {
			h5 {
				margin: 0.5rem 0.75rem;
			}
			table thead th {
				font-weight: normal;
			}
			input {
				max-width: 6rem;
			}
			.form-group {
				margin-bottom: 0;

				select {
					max-width: 12rem;
					-moz-appearance: auto;
					-webkit-appearance: auto;
					appearance: auto;
				}
			}
		}
	}
	.table-wrapper {
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
		margin: 0 0 1rem;
		overflow: hidden;

		.table-header {
			display: flex;
			justify-content: space-between;
			padding: 1rem;
		}

		table {
			display: table;
			width: 100%;
			font-size: 14px;

			thead {
				width: 100%;
				background-color: lightgray;
				font-weight: bold;
				margin-bottom: 0;

				th {
					list-style: none;
					padding: 0.5rem 0.25rem;

					&.actions {
						width: 16px;
					}
				}
				th:first-child {
					padding-left: 1rem;
				}
				th:last-child {
					padding-right: 1rem;
				}
			}
			tbody {
				min-height: 4rem;

				tr {
					width: 100%;
					margin-bottom: 0;

					td {
						list-style: none;
						padding: 0.5rem 0.25rem;

						&.quantity {
							display: flex;

							button {
								border-radius: 2px;
							}
							input {
								width: 4rem;
								margin: 0 0.5rem;
								background-color: $gray;
								border-radius: 4px;
								border: 1px solid lightgray;
								outline: none;
								text-align: center;
								transition: border ease-in 150ms;
							}
							input:focus {
								border: 1px solid gray;
							}
						}
					}
					td:first-child {
						padding-left: 1rem;
					}
					td:last-child {
						padding-right: 1rem;
					}
				}
				tr:nth-child(even) {
					background-color: $gray;
				}
			}
		}
	}
	.btn-remove {
		width: 16px;
		height: 16px;
		object-fit: contain;
		cursor: pointer;
	}
	.footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 2rem;
	}
	.totals-wrapper {
		margin: 0 0 1rem;

		&:has(.inverted) {
			margin: 0;

			.totals:last-child {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
		.totals {
			padding-left: 0;
			background-color: #152b42;
			color: $white;
			padding: 0.5rem 0;
			border-radius: 10px;
			width: 18rem;
			box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
			overflow: hidden;

			&.inverted {
				background-color: white;
				margin-bottom: 0;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;

				span {
					color: #152b42;
				}
				.amount {
					font-weight: bold;
				}
			}
			li {
				padding: 0.125rem 1rem;
				list-style: none;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.amount {
					text-align: right;
				}
				.bold {
					font-weight: bold;
				}
				.red {
					color: $danger;
				}
				.green {
					color: $green;
				}
			}
			.divider {
				height: 1px;
				background-color: gray;
				margin: 0.5rem 0;
			}
		}
	}
}

.modal-return-create {
	.form-group {
		input {
			font-size: 14px;
		}
	}
	table {
		border: collapse;
		width: 100%;

		thead tr {
			td {
				background-color: $blue2;
				color: white;
				padding: 0.5rem 0.25rem;
				font-size: 14px;
			}
			td:first-child, td:last-child {
				width: 8rem;
			}
		}
		tbody tr {
			td:first-child {
				padding: 0.25rem;
				width: 8rem;
			}
			td:last-child {
				padding: 0.25rem;
				width: 8rem;
				text-align: right;
			}
		}
	}
}

.modal-return-details {
	table {
		border: collapse;
		width: 100%;

		thead tr {
			td {
				background-color: $blue2;
				color: white;
				padding: 0.5rem 0.25rem;
				font-size: 14px;
			}
			td:first-child, td:last-child {
				width: 8rem;
			}
		}
		tbody tr {
			td:first-child, td:last-child {
				padding: 0.25rem;
				width: 8rem;
			}
		}
	}
	.modal-top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;

		p {
			margin-bottom: 0;
		}
		p.date {
			color: $blue;
		}
		p.conversion {
			color: $blue2;
			font-size: 18px;
			font-weight: bold;
		}
	}
	.modal-bottom {
		display: flex;
		justify-content: space-between;
		align-items: start;

		.form {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 1rem;
			padding: 1rem 0;

			.bill-number {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			input, textarea {
				width: 7rem;
				height: 2rem;
				padding: 0 0.5rem;
				border-radius: 6px;
				border: 1px solid lightgray;

				&.reason {
					padding: 0.5rem;
					width: 20rem;
					min-height: 5rem;
					max-height: 8rem;
				}
			}
			label {
				color: $blue2;
				font-weight: bold;
				margin-bottom: 0;
			}
		}
		.totals {
			width: 20rem;
			background-color: $gray;
			padding: 0.5rem;

			&>div {
				display: flex;
				justify-content: space-between;

				span {
					font-size: 18px;
					color: $blue2;
					font-weight: bold;
				}
			}
		}
	}
	.modal-actions {
		display: flex;
		justify-content: center;
		gap: 2rem;

		button:first-child {
			background-color: transparent;
			border: 2px solid $blue2;
			border-radius: 8px;
			padding: 0.25rem 1rem;
		}
		button:last-child {
			background-color: $blue;
			border: 2px solid $blue;
			border-radius: 8px;
			padding: 0.25rem 1rem;
			color: white;
		}
	}
}

.modal-report-sale-products {
  .modal-header {
    background-color: $blue;
  }
  .modal-title {
    font-size: 20px;
    color: $white;
  }
  .close {
    opacity: 1;
  }
  .close > span {
    color: $white !important;
  }
  .modal-body {
    padding: 0px;
  }
	.size-colors-form {
		display: flex;
		justify-content: space-between;
		gap: 1rem;

		.colors {
			.circle {
				cursor: pointer;
				width: 1.5rem;
				height: 1.5rem;
				border-radius: 50%;
				border-width: 5px;
				border-color: transparent;
			}
		}
		.colors, .sizes {
			flex: 1;

			select {
				padding: 0 0.5rem;
			}
		}
	}
	.price-discount {
		display: flex;
		justify-content: space-between;
		gap: 1rem;

		& > div {
			flex: 1;
		}
	}
	.size-colors-form .colors,
	.size-colors-form .sizes,
	.select-product,
	.select-price,
	.select-discount {

		.custom-actions-select-wrapper {
			.input-wrapper {
				height: 40px !important;
				border: 1px solid hsl(0,0%,80%) !important;
				border-radius: 4px;

				.form-control {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					border: none !important;
					padding: 0 0.5rem;
					background: $white !important;
				}
			}
		}
		.form-control {
			height: 40px;
			padding: 0 0.5rem;
			background-color: $white !important;
			border-radius: 4px;
			border: 1px solid hsl(0,0%,80%) !important;
			font-size: 14px !important;

			option {
				font-size: 16px;
			}
		}
		.fa-chevron-down {
			bottom: 14px !important;
			font-size: 12px !important;
		}
	}
  .container-data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 2rem 0;

		span {
			font-weight: bold;
		}
		.quantity-input {
			font-size: 40px;
			text-align: center;
			border: none;
			border-bottom: 2px solid hsl(0,0%,80%);
			outline: none;
			width: 70%;
		}
  }
	.actions {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		margin: 1rem 0;

		button {
			padding: 0.5rem 1rem;
		}
		button:last-child {
			background-color: #152b42 !important;
		}
	}
}