#visit-planner {
  scrollbar-gutter: stable;

  .tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;

    button {
      background-color: white;
      padding: 1rem 2rem;
      border-radius: 10px;
      outline: none;
      border: none;
      @include shadow();

      &.active {
        background-color: $blue;
        color: white;
      }
    }
  }
  .location-pin {
    height: 24px;
    cursor: pointer;
  }
  .link {
    color: $blue;
    cursor: pointer;
  }
  .container-totals {
    display: inline-flex;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .container-total {
      border-radius: 7px;
      padding: 10px;
      margin: 0 auto;
      background-color: #fff;
      @include shadow();
      align-self: flex-start;
      margin-right: 15px;
      margin-bottom: 15px;
      p.name {
        margin: 0px;
        color: $blue;
        font-size: 13px;
        text-transform: uppercase;
        font-family: 'Roboto Medium';
      }
      .container-values {
        display: flex;
        .item-value {
          margin-right: 10px;
        }
        p {
          margin-bottom: 0px;
          &.label {
            font-family: 'Roboto Medium';
            font-size: 12px;
            margin-top: 5px;
            line-height: 14px;
          }
          &.value {
            font-family: 'Roboto Bold';
            font-size: 25px;
          }
        }
      }
    }
  }
}
#visit-planner-create {
  .date {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .gray {
    text-align: right;
    color: darken($gray,35);
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .no-map {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .result-visit {
    font-size: 12px;
    margin-bottom: 0px;
    color: $blue;
  }
  .container-title {
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      position: relative;
      top: -5px;
      cursor: pointer;
    }
  }
  .btn-next {
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    width: 150px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    border: 3px solid $blue !important;
  }
  input.filter, select.filter, .react-datepicker-wrapper {
    background-color: white !important;
    border: 1px solid $gray !important;
    border-radius: 10px;
    height: 42px !important;
  }
  input[type="checkbox"] {
    appearance: none;
  }
  input[type="checkbox"]::before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    display: block;
    background-color: $gray;
    border-radius: 3px;
    border: 1px solid $gray;
  }
  input[type="checkbox"]:checked::before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    display: block;
    background-color: green;
    border-radius: 3px;
    border: 1px solid $gray;
  }
  section {
    flex-direction: column;

    span {
      font-size: 28px;
      font-weight: bold;
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;
      margin-top: 10px;

      button {
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        background-color: $white;
        border-radius: 10px;
      }
      button.alt-btn {
        background-color: #152c41;
        color: $white;
      }
      .interval-type {
        display: flex;
        gap: 1rem;

        button {
          width: 8rem;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }
        button.selected {
          background-color: $blue;
          color: $white;
        }
      }
    }
  }
  .table-wrapper {
    padding-top: 0.5rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .table-filters {
      padding: 0 1rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .by-date {
        width: 20%;
      }
      .by-search {
        width: 30%;
      }
      .note p {
        color: $danger;
      }
    }
    .interval-date {
      height: 2.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 2px;
      padding: 0 1rem;

      .selected-date {
        font-weight: bold;
        text-transform: capitalize;

        span {
          text-transform: lowercase;
        }
      }
      .week-day {
        background-color: $gray;
        border: none;
        outline: none;
        width: 7rem;
        padding: 0.5rem 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          background-color: #152c41;
          color: $white;
        }
        &.active {
          background-color: $blue;
          color: $white;
        }
      }
    }
    .table {
      font-size: 14px;

      .header {
        // display: grid;
        // grid-template-columns: minmax(0,30px) minmax(0,20%) minmax(0,1fr) minmax(0,1fr) minmax(0,7%) minmax(0,1fr) minmax(0,20%) minmax(0,20%);
        font-family: 'Roboto Bold';
      }
      .header > span:first-child {
        padding-left: 0.5rem;
      }
      .header > span:last-child {
        padding-right: 0.5rem;
      }
      .body {
        max-height: 400px;
        overflow-y: auto;

        .tr {
          // display: grid;
          // grid-template-columns: minmax(0,30px) minmax(0,20%) minmax(0,1fr) minmax(0,1fr) minmax(0,7%) minmax(0,1fr) minmax(0,20%) minmax(0,20%);
          align-items: center;
          padding: 0.25rem 0;

          &>div {
            padding-right: 0.25rem;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .location-pin {
            cursor: pointer;
            height: 20px;
          }
          .form-group {
            margin-bottom: 0;
          }
        }
        .tr > div:first-child {
          padding-left: 0.5rem;
        }
        .tr > div:last-child {
          padding-right: 0.5rem;
        }
      }
      .footer {
        padding: 1rem;
      }
    }
  }
  .btn-save {
    margin-top: 35px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-transform: uppercase;
  }
  .btn-remove {
    background-color: transparent !important;
    outline: 0px !important;
    box-shadow: none !important;
    border: 0px !important;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
  .week-day {
    text-transform: capitalize;
  }
  .seller {
    color: $blue;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .title {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .zones {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .table-wrapper-route {
    padding: 20px;
    margin-top: 20px;
  }
  .btn-back {
    background-color: transparent !important;
    color: $black !important;
    border: 3px solid $black !important;
    margin-right: 15px;
  }
  .container-buttons {
    margin-top: 20px;
  }
  #map-planner-create {
    width: 100%;
    height: 400px;
  }
  .seller-title {
    margin-bottom: 15px;
  }
  .name {
    margin-bottom: 5px;
    font-weight: bold;
  }
  .address {
    margin-bottom: 5px;
  }
  th {
    color: #8b8f91;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  td {
    border-bottom: 1px solid #8b8f91;
  }
}
#visit-unplanned-details {
  section.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .filters {
      display: flex;
      gap: 1rem;

      .form-group {
        width: 150px;
        margin-bottom: 0;
      }
    }
    .views {
      display: flex;

      gap: 1rem;

      button {
        width: 10rem;
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        background-color: $white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        font-family: 'Roboto Bold';
      }
      button.selected {
        background-color: #152c41;
        color: $white;
      }
    }
  }
  section.tab-content {
    background-color: white;
    @include shadow();
    margin: 1rem 0;
    border-radius: 12px;
    
    &:has(.tab-route) {
      padding: 1rem;
    }
  }
  .tab-route {
    display: grid;
    grid-template-columns: 45% 55%;

    .map {
      padding-right: 1rem;
    }
    #map-planner-view {
      width: 100%;
      height: 500px;
      padding-right: 1rem;
    }
    .interval-date {
      height: 2.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 2px;

      .week-day {
        background-color: $blue;
        color: $white;
        font-size: 13px !important;
        border: none;
        outline: none;
        width: 7rem;
        padding: 0.5rem 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          background-color: #152c41;
        }
        &.active {
          background-color: $blue;
        }
      }
    }
    .seller {
      color: $blue;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .title {
      margin-bottom: 5px;
      font-size: 18px;
    }
    .name {
      font-weight: bold;
    }
    .zones {
      font-size: 18px;
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 5px;
    }
    .blue {
      font-size: 18px;
      color: $blue;
      font-weight: bold;
      margin-top: 10px;
    }
    .date {
      font-size: 18px;
    }
    .gray {
      color: darken($gray,30);
      font-size: 14px;
    }
    .comments {
      margin-bottom: 20px;
    }
    .location {
      display: flex;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 5px;
      }
    }
    .container-check {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 10px;
      .check {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: $gray;
        border: 1px solid darken($gray,10);
        margin-right: 10px;
        &.active {
          background-color: $green;
        }
      }
    }
  }
  .table-wrapper {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .table-filters {
      padding: 0 1rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .by-date {
        width: 20%;
      }
      .by-search {
        width: 30%;
      }
      .note p {
        color: $danger;
      }
    }
    .interval-date {
      height: 2.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 2px;
      padding: 0 1rem;

      .selected-date {
        font-weight: bold;
        text-transform: capitalize;

        span {
          text-transform: lowercase;
        }
      }
      .week-day {
        background-color: $gray;
        border: none;
        outline: none;
        width: 7rem;
        padding: 0.5rem 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          background-color: #152c41;
          color: $white;
        }
        &.active {
          background-color: $blue;
          color: $white;
        }
      }
    }
    .table {
      font-size: 14px;

      .header {
        background-color: $blue;
        font-family: 'Roboto Bold';
        color: white;
      }
      .header > td:first-child {
        padding-left: 0.5rem;
      }
      .header > td:last-child {
        padding-right: 0.5rem;
      }
      .body {
        max-height: 400px;
        overflow-y: auto;

        tr {
          align-items: center;
          padding: 0.25rem 0;

          &>div {
            padding-right: 0.25rem;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .location-pin {
            cursor: pointer;
            height: 20px;
          }
          .form-group {
            margin-bottom: 0;
          }
        }
        .tr > div:first-child {
          padding-left: 0.5rem;
        }
        .tr > div:last-child {
          padding-right: 0.5rem;
        }
      }
      .footer {
        padding: 1rem;
      }
    }
  }
  .btn-save {
    margin-top: 35px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-transform: uppercase;
  }
  .btn-remove {
    background-color: transparent !important;
    outline: 0px !important;
    box-shadow: none !important;
    border: 0px !important;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
  .table-wrapper-route {
    padding: 20px;
    margin-top: 20px;
  }
}

.modal-autosale-create {
  .modal-header {
    background-color: $blue;
  }
  .modal-title {
    font-size: 20px;
    color: $white;
  }
  .close {
    opacity: 1;
  }
  .close > span {
    color: $white !important;
  }
  .modal-body {
    padding: 1rem 0;
  }
  .push-btn {
    outline: none;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    padding: 0.5rem;
    color: gray;

    &.active {
      color: $blue;
    }
  }
  .form {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 1rem;

    &.form-col-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  table {
    margin: 1rem 0;
    width: 100%;

    tr td:first-child, tr th:first-child {
      padding-left: 1rem;
    }
    thead tr {
      background-color: $gray;

      th {
        padding: 0.5rem 0;
        white-space: no;
      }
    }
    tbody {
      tr {
        td {
          padding: 0.75rem 0;
          border-bottom: 1px solid $gray;

          .action {
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
          &.no-items {
            text-align: center;
          }
        }
      }
    }
  }
}

.modal-autosale-report-view {
  .modal-body {
    padding-top: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .header-title {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    color: $blue;
  }
  .header-subtitle {
    margin-bottom: 0;
    font-weight: bold;
  }
  table {
    margin-top: 1rem;
    font-size: 14px;

    th {
      word-break: normal;
    }
    tr {
      border-bottom: 1px solid lightgray;

      td {
        padding: 0.5rem 0.25rem;
      }
    }
  }
}

.modal-planner-sellers {
  .modal-header {
    background-color: $blue;
  }
  .modal-title {
    font-size: 20px;
    color: $white;
  }
  .close {
    opacity: 1;
  }
  .close > span {
    color: $white !important;
  }
  .container-search {
    background-color: $blue;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .modal-body {
    padding: 0px;
  }
  .item-seller {
    padding: 10px;
  }
  .th-header {
    font-size: 14px;
  }
  .status-red {
    color: $danger;
  }
  .status-green {
    color: $green;
  }
  .container-data {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.modal-planner-view {
  .modal-header {
    display: none;
  }
  .result-visit {
    font-size: 12px;
    margin-bottom: 0px;
    color: $blue;
  }
  .modal-body {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .modal-dialog {
    max-width: 90%;
  }
  #map-planner-view {
    width: 100%;
    height: 500px;
  }
  .btn-close {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0px !important;
    border: 0px !important;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .seller {
    color: $blue;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .title {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .name {
    font-weight: bold;
  }
  .zones {
    font-size: 18px;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
  }
  .blue {
    font-size: 18px;
    color: $blue;
    font-weight: bold;
    margin-top: 10px;
  }
  .date {
    font-size: 18px;
  }
  .gray {
    color: darken($gray,30);
    font-size: 14px;
  }
  .comments {
    margin-bottom: 20px;
  }
  .location {
    display: flex;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 5px;
    }
  }
  .container-check {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;
    .check {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      background-color: $gray;
      border: 1px solid darken($gray,10);
      margin-right: 10px;
      &.active {
        background-color: $green;
      }
    }
  }
}

.modal-planner-view-route {
  #map-planner-view-route {
    width: 100%;
    height: 500px;
  }
  .modal-header {
    display: none;
  }
  .modal-body {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .modal-dialog {
    max-width: 90%;
  }
  .btn-close {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0px !important;
    border: 0px !important;
    margin-bottom: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.modal-planner-view-client {
  #map-planner-view-client {
    width: 100%;
    height: 500px;
  }
  .modal-header {
    display: none;
  }
  .modal-body {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .modal-dialog {
    max-width: 700px;
  }
  .btn-close {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0px !important;
    border: 0px !important;
    margin-bottom: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.modal-planner-view-photo {
  position: absolute;
  background-color: #000000AA;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  border-radius: 10px;

  .close-photo {
    position: absolute;
    top: 18px;
    right: 22px;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      filter: invert(1) drop-shadow(0 0 1px black);
    }
  }
  .photo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      width: 95%;
      height: 95%;
      object-fit: contain;
    }
  }
}