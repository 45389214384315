// Datepicker

.react-datepicker-wrapper {
	display: block;
}

.form-group.datepicker.material {
	label {
		font-size: medium !important;
    color: #3E4756;
	}
}

.container-datepicker {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 14px;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	min-width: 100%;
	height: 29px;
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-bottom: 10px;
	padding-right: 10px;
	padding-left: 10px;
	&.white {
		background-color: $white !important;
	}
	&.material {
		justify-content: space-between;
		font-size: medium !important;
    color: #3E4756;
	}
	p {
		min-width: 100%;
		margin: 10px;
	}
	i.fa {
		position: absolute;
		right: 10px;
	}
}

.label-datepicker {
	display: block;
}