.table-sales,
.table-request,
.table-notes,
.table-orders,
.table-products {
	overflow: auto;
	max-height: 240px;

	tbody tr {
		font-size: 13px;

		td {
			vertical-align: middle;
		}
	}

	tr.item-selected {
		background-color: rgba($blue,.1);
	}

	input.input-white {
		background-color: #fff !important;
	}
}

.note-serialize {
	font-size: 10px;
	font-family: 'Roboto Light';
	margin: 0px;
	margin-bottom: 5px;
}

.container-btn-actions-sales {
	display: flex;
	flex-direction: 'row';
	
	.btn-actions-orders {
		margin-left: 5px;
		margin-right: 5px;
	}
}

.table-warehouses {
	overflow: auto;
	max-height: 250px;

	tbody tr {
		font-size: 13px;

		td {
			vertical-align: middle;
		}
	}
}

.total {
	font-size: 2rem;
	font-weight: bold;

	>span {
		font-size: 3rem;
	}
}

.modal-green {
	.checkmark {
		display: block;
		font-size: 12rem;
	}

	.f-big {
		font-size: 2rem;
		font-weight: bolder;
		margin: 2rem;
	}
}

.clearClient{
	float: left;
    margin-top: 36px;
    margin-left: -20px;
}