
@media only screen and(min-width: 976px) and(max-width: 1600px){
   
    #products #row1 .check{
        /*background-color: aqua;*/
        /*display: flex;*/
        margin-top: 20px;
    }

}

@media only screen and(min-width: 768px) and(max-width: 940px){
   
    #products .row .check .check1{
        
         display: inline-flex;
       /* flex-direction: row;
        flex-wrap: wrap;
        min-height: 100%;*/
    }


}

@media all and (max-width: 400px) {
    #products .row .check .check1{
        
        /*display: inline-block;*/
    }
}

@media only screen and(min-width: 380px) and(max-width: 678px){

    #products .row .check .check1{
        display: inline-block;
    }


}


.report-commisions table thead tr th:nth-child(4) {
    // text-align: right;
}

#report-returns {
    td:last-child {
        min-width: 220px;
    }
    .print-badge {
        cursor: pointer;
        background-color: $black;
        color: white;
        padding: 0.25rem 0.5rem;
        font-size: 11px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .edit-circle {
        background-color: $blue;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;

        i {
            color: white;
            font-size: 14px;
        }
    }
    .edit-circle:last-child {
        margin-right: 0;
    }
}