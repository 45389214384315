// Select
.form-group {
	position: relative;
}

select.form-control {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 13px;
	height: 30px;
	-moz-appearance:none;
	-webkit-appearance:none;
	appearance:none;
	&.filter {
		height: 40px;
		border-radius: 10px;
		font-size: medium;
	}
	&.material {
		background-color: transparent !important;
		border: none;
		height: 40px !important;
		border-bottom: 2px solid $input !important;
		font-size: medium;
		padding: 0;
	}
	&.white {
		background-color: $white !important;
	}
	&.input-gray{
		background-color: $gray !important;
	}
	&.input-white {
		background-color: $white !important;
	}
	&.mui {
		height: 36px !important;
		border: 2px solid #f1f1f1 !important;
		border-bottom-color: $blue !important;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		transition: all 200ms ease;
	}
	&.mui:focus, &.mui.filled {
		background-color: white !important;
		border-color: $blue !important;
		border-radius: 6px;
	}
	&.mui:invalid {
		background-color: white !important;
		border-color: $danger !important;
		border-radius: 6px;
	}
	&.mui ~ label {
		color: gray;
		background-color: #f1f1f1;
		font-size: 14px;
		font-weight: normal;
		position: absolute;
		pointer-events: none;
		left: 14px;
		top: 8px;
		transition: all 200ms ease;
	}
	&.mui:focus ~ label, &.mui.filled ~ label {
		background-color: white;
		font-size: 12px;
		top: -8px;
		left: 9px;
		padding: 0 5px;
	}
	&-sm{
		border-radius: 3px;
		background-color: $input !important;
		outline: 0px !important;
		box-shadow: none !important;
		border: 0px !important;
		font-size: 13px;
		&.white {
			background-color: $white !important;
		}
	}
}

.custom-actions-select-wrapper {
	position: relative;
	width: 100%;

	.custom-actions-select {
		position: relative;
		width: 100%;
		cursor: pointer;

		.input-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			border-bottom: 2px solid #f1f1f1 !important;
			overflow: hidden;

			&.no-border {
				border: none;
			}
			.form-control {
				background-color: white;
				border: none !important;
				border-radius: 0;
				padding-left: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 40px !important;
			}
			.dropdown-tip {
				width: 40px;
				height: 40px;
				background-color: white;
				display: flex;
				justify-content: center;
				align-items: center;

				i {
					right: 0.5rem;
					font-size: 20px;
					color: gray;
				}
			}
		}

	}
	.custom-actions-options {
		width: 100%;
		max-height: 20rem;
		overflow-y: auto;
		background-color: white;
		@include shadow();
		position: absolute;
		z-index: 10;
		margin-top: -1rem;
		padding: 0;

		li {
			list-style: none;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.25rem 0.5rem;
			cursor: pointer;

			&.disabled {
				cursor: initial !important;

				.label {
					color: #21252988;
				}
			}
			&.action-as-option,
			&.bottom-action-as-option {
				&.green {
					color: $green;
				}
				&.blue {
					color: $blue;
				}
			}
			.label {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding-right: 0.5rem;
				flex: 1;
			}
			.actions {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}

	.circle {
		cursor: pointer;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
	}
}
.backdrop {
	position: fixed;
	z-index: 9;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.select-action-as-filter {
	.custom-actions-select-wrapper {
		.input-wrapper {
			border-radius: 10px;

			.form-control {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				border: none !important;

				span {
					padding: 0 0.5rem;
				}
			}
			input.form-control {
				padding: 0 0.5rem;
				background: white !important;
			}
			.fa-chevron-down {
				font-size: 12px;
			}
		}
	}
}