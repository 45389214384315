.whatsapp-order-details {
  .product-item {
    padding: 10px 0;
    border-bottom: 1px solid lightgray;

    .product-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .product-details {
      display: flex;
      flex-direction: row;
      align-items: center;

      .product-color {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: 5px;
      }
      .product-size {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    button.delete {
      border: none;
      background-color: transparent;
    }
    .accept-reject {
      display: flex;
      gap: 1rem;
    }
  }
}

.whatsapp-select-client {
  .tab-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-self: center;
    margin: 0 2rem 1rem 2rem;

    .tab {
      padding: 5px;
      width: 100%;
      border-radius: 30px;
      align-self: center;
      background-color: $white;
      border: 1px solid $black;

      &.active {
        background-color: $blue;
        border: 1px solid $blue;

        span {
          color: $white;
        }
      }
    }
    .tab:disabled {
      border-color: rgba(16, 16, 16, 0.3);
    }
  }
  .reset-filter {
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      background-color: transparent;
      outline: none;
    }
  }
  .client-list {
    .client-item {
      cursor: pointer;

      &.it-match {
        background-color: $blue;
        color: $white;
      }
    }
    .client-item:hover {
      background-color: $gray;
      color: $black;
    }
  }
  .fetch-more {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}

.whatsapp-convert-to-order {
  .product-list {
    .product-item {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $gray;
      width: 100%;
      padding: 1rem 0;

      .product-image {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .product-info {
        width: 70%;
        padding-left: 0.5rem;

        .product-name, .product-price {
          font-size: 12px;
        }

        .product-details {
          display: flex;
          flex-direction: row;
          align-items: center;

          .product-color {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            margin-right: 5px;
          }
          .product-size {
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
      .discount-error {
        font-size: 11px;
        color: $danger;
        margin-bottom: 0.5rem;
      }
    }
  }
  .total-amount {
    background-color: $gray;
    color: gray;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .tab-container {
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    margin-bottom: 20px;

    .tab {
      cursor: pointer;
      width: 100%;
      margin: 0 1rem;
      padding-top: 10px;
      padding-bottom: 6px;
      border-radius: 10px;
      align-self: center;
      background-color: white;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 2px solid white;
      @include shadow();

      &.selected {
        border-bottom: 2px solid $green;
      }
    }
  }
	.input-container {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}
  .container-payments {
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 10px;

    .payment-method {
      display: flex;
      flex-direction: row;

      .payment-method-desc {
        flex: 1;
      }
      .payment-method-delete {
        margin-left: 1rem;

        button {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
  .container-buttons {
    display: flex;
    flex-direction: column;

    button {
      background-color: $green;
      border: none;
      outline: none;
      flex: 1;
      padding: 0.5rem 0;
      border-radius: 30px;

      span {
        color: white;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
    button.danger {
      background-color: transparent;

      span {
        color: red;
      }
    }
    button:first-child {
      margin-bottom: 1rem;
    }
  }
}