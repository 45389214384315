// Variables

$gray: #e8e8e8;
$blue: #3A7FC2;
$blue2: #0b0b51;
$black: #222d32;
$white: #fff;
$input: #f1f1f1;
$active: #0d1214;
$danger: #DD4B4B;
$green: #38CE6A;
$orange: #FB8C00;
$yellow: #FBC02D;
$purple: #5c068c;
$square-menu: rgba(0,0,0,0.1);
$gray-background: #f6f9fc;
$text: #191c21;