// Button

.btn-primary {
	background-color: $blue;
	border-radius: 3px;
	color: #fff !important;
	min-width: 120px;
	outline: 0px !important;
	border: 0px !important;
	box-shadow: none !important;
	font-size: 14px;
	padding-top: 3px;
	padding-bottom: 5px;
}

.btn-primary.disabled, .btn-primary:disabled {
	background-color: lightgray !important;
	color: gray !important;

	span {
		color: gray !important;
	}
}

.primary, .secondary {
	height: 40px;
	border-radius: 10px;

	span {
		font-family: 'Inter Medium';
	}
}

.secondary {
	background: $input;

	span {
		color: $blue;
	}
}

.btn-filter {
	background-color: white !important;
	height: 40px;
	border-radius: 10px;

	span, p {
		font-family: 'Inter SemiBold';
		color: $blue;
		margin-bottom: 0;
	}
}

.btn-white {
	background-color: white !important;
	color: $blue;

	span {
		color: black !important;
	}
}

.btn-blue {
	background-color: $blue !important;
}

.btn-block {
	width: 100%;
}

.btn-outline {
	border: 1px solid $gray !important;
	background-color: transparent !important;
	min-width: auto !important;
	border-radius: 3px !important;
}

.btn-red {
	background-color: $danger !important;
}

.btn-green {
	background-color: $green !important;
}

.btn-orange {
	background-color: $orange !important;
}

.btn-yellow {
	background-color: $yellow !important;
}

.btn-small {
	padding: 2.5px !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	font-size: 10px !important; /*px12 */
	min-width: 23px !important; /*px30*/
}

.btn-align-bottom {
	@media (min-width: 768px) {
		margin-top: 31.10px!important;
		justify-content: center!important;
	}

	margin-top: 19.5px!important;
	margin-bottom: 10px!important;
}

.margin-auto {
	margin: auto;
}