// View Order

.view-order, .view-delivery, .view-requests {
	.view-serials {
		margin: 0px;
		color: $blue;
		font-size: 12px;
		cursor: pointer;
	}
}

.view-request-modal {

	.payment-method-title {
		margin-top: 20px;
		color: $blue;
		font-family: 'Inter SemiBold';
		text-align: center;
	}
	.payment-method-subtitle {
		margin-top: 10px;
		color: #334768;
		font-family: 'Inter SemiBold';
		text-align: center;
	}
	.payment-method-price, .payment-method-price span {
		color: #334768;
		font-size: 24pt;
		font-family: 'Inter SemiBold';
		text-align: center;

		&.purple {
			color: $blue;
		}
	}
	.payment-methods {
		margin-top: 20px;
		margin-bottom: 20px;
		display: grid;
		grid-template-columns: 45% 45%;
		column-gap: 50px;
		row-gap: 20px;

		.payment-method {
			display: flex;
			flex-direction: row;
			align-items: center;

			.payment-method-left {
				display: flex;
				flex: 1;
				flex-direction: row;
				align-items: center;
				gap: 10px;

				& > input[type="checkbox"] {
					width: 18px;
					height: 18px;
				}
				img {
					width: 24px;
				}
				& > label, & > div > div > label {
					font-family: 'Inter Regular';
					font-size: 12pt !important;
				}
				.extra-options {
					display: flex;
					flex-direction: row;
					gap: 20px;

					.extra-option {
						label {
							margin-left: 10px;
							font-size: 11pt;
						}
					}
				}
			}
			.payment-method-right {
				flex: 1;
			}
		}
	}
}