// Create edit user

.container-create-edit-quote {
	.product-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0.5rem 0;
		border-bottom: 1px solid $gray;

		.product-image {
			img {
				width: 72px;
				height: 72px;
			}
		}
		.product-details {
			padding: 0 1rem;
			display: flex;
			flex: 1;
			flex-direction: column;
			width: 100%;
			overflow: hidden;

			.product-name {
				font-size: 14px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.product-price {
				font-size: 14px;
			}
			.product-qty-section {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0.5rem 0;

				.product-qty {
					width: 60px;
					height: 25px;
					margin: 0 1rem;
					padding-top: 2px;
					text-align: center;
					font-size: 14px;
					border-radius: 5px;
					background-color: $gray;
					border: none;
					outline: none;
				}
				.product-qty-button {
					cursor: pointer;
					padding: 0px 15px;
					border-radius: 5px;
					background-color: $blue;
					text-align: center;
					justify-content: center;
					align-items: center;

					span {
						color: white;
						font-size: 18px;
					}
				}
			}
			.colors {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-bottom: 1rem;

				.color {
					width: 25px;
					height: 25px;
					cursor: pointer;
					border-radius: 12.5px;
					border-width: 5px;
					margin-bottom: 0.5rem;
					margin-right: 0.5rem;
					border-color: transparent;

					&.selected {
						border: 5px solid #00000066;
					}
				}
			}
			.size {
				border-radius: 10px;
				border-bottom-left-radius: 10px;
				border-top-left-radius: 10px;
			}
		}
		.product-trash {
			cursor: pointer;
			img {
				width: 24px;
				height: 24px;
			}
		}
	}
	.total-price {
		margin-top: 1rem;
		padding: 0.5rem 1rem;
		background-color: $gray;
		border-radius: 16px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		div {
			font-size: 14px;
		}
	}
	.tab-container {
		display: flex;
		justify-content: space-evenly;
		align-self: center;
		margin-bottom: 20px;
	
		.tab {
		  cursor: pointer;
		  width: 100%;
		  margin: 0 1rem;
		  padding-top: 10px;
		  padding-bottom: 6px;
		  border-radius: 10px;
		  align-self: center;
		  background-color: white;
		  text-align: center;
		  text-transform: uppercase;
		  border-bottom: 2px solid white;
		  @include shadow();
	
		  &.selected {
			border-bottom: 2px solid $green;
		  }
		}
	}
	.row {
		margin: 1rem 0;
		display: flex;
		padding-left: 0;
		padding-right: 0;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		
		& > div {
			flex: 1;
		}
	}
	.add {
		background-color: transparent;
		outline: none;
		border: none;

		img {
			width: 24px;
			height: 24px;
		}
	}
	.no-items {
		text-align: center;
	}
}

#container-create-edit-quote-select-product {
	.product-totals {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.5rem;

		.done {
			width: 3rem;
			height: 2rem;
			background-color: $blue;
			outline: none;
			border: none;
			border-radius: 6px;
			color: white;
		}
	}
	.product-item {
		display: flex;
		cursor: pointer;
		flex-direction: row;
		align-items: center;
		padding: 0.5rem 0;
		border-bottom: 1px solid $gray;

		&.selected {
			background-color: rgba($blue, 0.25);
		}
		.product-image {
			img {
				width: 48px;
				height: 48px;
			}
		}
		.product-details {
			padding: 0 1rem;
			display: flex;
			flex: 1;
			flex-direction: column;
			width: 100%;
			overflow: hidden;

			.product-name {
				font-size: 14px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
	.product-item:hover {
		background-color: $gray;
	}
}