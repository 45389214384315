.container-create-edit-purchase {
	.react-datepicker-popper {
		z-index: 10;
	}
	.header-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem 2rem;
	}
	.product-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0.5rem 0 0;
		margin-bottom: 1rem;
		border-bottom: 1px solid $gray;

		header {
			display: inline-flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			background-color: #f2f2f2;
			padding: 0.25rem 0.5rem;
			margin-bottom: 0.5rem;

			.product-name {
				font-size: 14px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin-bottom: 0;
			}
			.product-trash {
				margin: auto;
				cursor: pointer;
				img {
					width: 18px;
					height: 18px;
				}
			}
		}
		.product-details {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			flex: 1;
			width: 100%;
			overflow: hidden;

			.grid1 {
				grid-column: 1 / 2;
			}
			.grid2 {
				grid-column: 1 / 3;
			}
			.product-price {
				font-size: 12px;
			}
			.product-qty-section {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0.5rem 0;
			}
			.colors {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-bottom: 1rem;

				.color {
					width: 20px;
					height: 20px;
					cursor: pointer;
					border-radius: 12.5px;
					border-width: 5px;
					margin-bottom: 0.5rem;
					margin-right: 0.5rem;
					border-color: transparent;

					&.selected {
						border: 5px solid #00000066;
					}
				}
			}
			.size {
				border-radius: 10px;
				border-bottom-left-radius: 10px;
				border-top-left-radius: 10px;
			}
		}
	}
	.total-price {
		margin-bottom: 1rem;
		padding: 0.5rem 1rem;
		background-color: $gray;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		&.blue {
			background-color: $blue2;
			color: white;
		}
		div {
			font-size: 14px;
		}
		div:last-child {
			width: 14rem;
			text-align: right;
		}
	}
	.tab-container {
		display: flex;
		gap: 2rem;
		justify-content: space-evenly;
		align-self: center;
		margin-bottom: 1rem;

		.tab {
			cursor: pointer;
			width: 100%;
			align-self: center;
			background-color: #AAAAAA;
			color: white;
			padding: 10px 0;
			text-align: center;
			text-transform: uppercase;

			&.selected {
				background-color: #238C00;
			}
		}
	}
	.row {
		margin: 1rem 0;
		display: flex;
		padding-left: 0;
		padding-right: 0;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		
		& > div {
			flex: 1;
		}
	}
	.add {
		background-color: transparent;
		outline: none;
		border: none;

		img {
			width: 24px;
			height: 24px;
		}
	}
	.table-actions {
		display: flex;
		justify-content: flex-end;
		gap: 0.5rem;
		border-top: 1px solid $gray;
		padding: 0.5rem 0;
	}
	table {
		width: calc(100% - 5px);
		border: 1px solid $gray;
		display: block;
		height: 14rem;
		overflow-y: auto;
		scrollbar-gutter: stable;

		thead {
			display: table;
			position: sticky;
			width: 100%;
			top: 0;
			z-index: 1;

			th {
				padding: 0.5rem;
				background-color: white;
				border-bottom: 1px solid $gray;
				font-size: 14px;
			}
			th:last-child {
				text-align: right;
			}
		}
		tbody {
			display: table;
			width: 100%;

			tr {
				width: 100%;
				align-items: center;
			}
			td {
				display: table-cell;
				padding: 0 0.5rem;
				border: none;
			}
			td:last-child {
				text-align: right;
				padding-right: 0.5rem;
			}
			td {
				font-size: 13px;
			}
			.product-name {
				position: relative;
				.is-size-color {
					cursor: pointer;
					color: $blue;
				}
				& .size-color-pop-up {
					display: none;
					width: 14rem;
					translate: 0 -100%;
					background-color: white;
					border: 1px solid #AAAAAA;
					position: absolute;
					z-index: 5;
					padding: 0.5rem;
					// @include shadow();
				}
			}
			.product-name:hover {
				& .size-color-pop-up {
					display: block;
				}
			}
			.form-group {
				margin-bottom: 0;
				border-bottom: 1px solid $gray;
				input {
					background-color: transparent !important;
					text-align: center;
				}
			}
			.remove-product {
				cursor: pointer;
				color: red;
				font-size: 16px;
			}
		}
		thead tr, tbody tr {
			width: 100%;
			display: flex;
			flex-direction: row;

			th, td:not(.no-items) {
				flex: 1;

				&:nth-child(1) {
					flex: 0 0 100px;
					text-align: center;
				}
				&:nth-child(2) {
					flex: 0 0 100px;
					text-align: center;
				}
				&:nth-child(3) {
					flex-grow: 3;
				}
				&:nth-child(7) {
					flex: 0 0 140px;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					gap: 0.75rem;
				}
			}
		}
	}
	table::-webkit-scrollbar {
		width: 4px;
		border-left: 1px solid $gray;
	}
	table::-webkit-scrollbar-thumb {
		background-color: $gray;
	}
	.no-items {
		width: 100%;
		text-align: center;
		padding: 2rem 0;
	}
	.dates {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		padding: 0;

		li {
			list-style: none;
			cursor: pointer;
			border: 1px solid $blue;
			padding: 0.25rem 0.5rem;
			border-radius: 5px;
			transition: 200ms;
			font-size: 14px;
		}
		li:hover {
			background-color: #DD4B4B36;
			border: 1px solid $danger;
		}
	}
}